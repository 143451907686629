import http from './http';
import toastItem from '../Components/presentation/toastItem';

const addTeamMenber = async (ibmId, name, email, band, hoursWorked, cost, department) => {
  // TODO document why this async arrow function is empty

  try {
    await http({
      method: 'POST',
      url: '/api/person',
      data: {
        ibmId,
        name,
        email,
        band,
        hoursWorked,
        cost,
        department,
      },
    });
    toastItem(true, 'SUCCESS: New Team member added');
  } catch (err) {
    toastItem(false, 'ERROR: Could not add new member');
  }
};

export default addTeamMenber;

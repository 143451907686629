import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { useDashboardContext } from '../Pages/DashboardContext';
import './pagination.css';

function Pagination({ totalBidsResponse }) {
  const {
    limit, setOffset, setLoading, currentPage, setCurrentPage,
  } = useDashboardContext();

  function calcPageRange() {
    const width = window.innerWidth;
    return Math.ceil((width - 600) / 54);
  }

  const [datalength, setDataLength] = useState(10);
  const [nPages, setNPages] = useState();
  // UseState to define width of the pagination component using the width of the window.
  const [pageRange, setPageRange] = useState(calcPageRange());

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    let pageOffset;
    if (limit === undefined) {
      pageOffset = (selected * 10);
    } else {
      pageOffset = (selected * limit);
    }
    setOffset(pageOffset);
    setLoading(true);
  };

  useEffect(() => {
    setDataLength(totalBidsResponse);
    const handlePageRange = () => {
      setPageRange(calcPageRange());
    };

    if (limit === undefined) {
      setNPages(Math.ceil(datalength / 5));
    } else {
      setNPages(Math.ceil(datalength / limit));
    }

    window.addEventListener('resize', handlePageRange);
    return () => {
      window.removeEventListener('resize', handlePageRange);
    };
  }, [totalBidsResponse, datalength]);

  // Total number of pages for the pagination component
  // const nPages = Math.ceil(datalength / limit);

  return (
    <div className="text-center">
      <ReactPaginate
        breakLabel="..."
        previousLabel="&lsaquo;"
        nextLabel="&rsaquo;"
        pageCount={nPages}
        pageRangeDisplayed={pageRange}
        onPageChange={changePage}
        forcePage={currentPage}
        containerClassName="paginationBttns"
        activeClassName="paginationActive"
      />
    </div>
  );
}

Pagination.propTypes = {
  totalBidsResponse: PropTypes.instanceOf(Object).isRequired,
};
export default Pagination;

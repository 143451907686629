import { useState, useEffect } from 'react';
import getRates from '../services/getRates';

const useGetRates = ({ like }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const result = await getRates({
        like,
      });
      setResponse(result.data);
    } catch (oops) {
      setError(oops);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // execute once only

  return { response, error };
};

export default useGetRates;

import http from './http';
import getBid from './getBid';
import toastItem from '../Components/presentation/toastItem';

const removeMember = async (person, bid, setBid) => {
  const personIndex = bid.team.indexOf(person);
  bid.team.splice(personIndex, 1);
  try {
    await http({
      method: 'PUT',
      url: '/api/bid',
      data: {
        bid,
      },
    });
    await http({
      method: 'PUT',
      url: '/api/person',
      data: {
        _id: person._id,
        ibmId: person.ibmId,
        name: person.name,
        band: person.band,
        department: person.department,
        bidsWorked: person.bidsWorked,
      },
    });
    const bidResponse = await getBid({
      reference: bid.salesCloudReference,
    });

    setBid(bidResponse.data);
    toastItem(true, `SUCCESS: Team Member ${person.name} removed!`);
  } catch (err) {
    toastItem(false, 'ERROR: Call to database failed!');
  }
};

export default removeMember;

import http from './http';

async function getMembers() {
  const result = await http({
    method: 'GET',
    url: '/api/person/all',
  });
  return result.data;
}

export default getMembers;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import Modal from '../../presentation/Modal';
import Button from '../../presentation/Button';
import toastItem from '../../presentation/toastItem';

function LineChartModal({
  themes, modalData, date, open, close,
}) {
  if (themes.length === 0) return null;
  const [theme, setTheme] = useState(themes[0]);
  const [currentData, setCurrentData] = useState([]);

  const navigate = useNavigate();

  // set the initial theme
  useEffect(() => {
    if (themes.length !== 0) {
      setTheme(themes[0].value);
    }
  }, [themes]);

  // set accordion data
  useEffect(() => {
    if (theme !== 'undefined') {
      const bids = modalData[theme];
      const accordionDataFormat = bids?.map((bid, index) => ({
        uuid: index,
        // eslint-disable-next-line react/jsx-one-expression-per-line
        heading: <strong>Bid Name: {bid.bidName}</strong>,
        content: bid.feedbackData?.map((question, i) => (
          <>
            <strong>
              Question
              {' '}
              {i + 1}
              {') '}
              Score:
              {' '}
              {question.percent}
              %
            </strong>
            <p>
              <u>FeedBack:</u>
              {' '}
              {question.feedback}
            </p>
          </>
        )),
        salesCloudReference: bid.salesCloudReference,
      }));
      setCurrentData(accordionDataFormat);
    }
  }, [theme]);

  const openBid = ({ ISC_REF, bidName }) => {
    // close modal
    close.current();
    // navigate to the bid view by changing the fragment identifier in the url.
    navigate('../bidview', { replace: true, state: { ISC_REF } });
    // toast
    toastItem(true, `Opened Bid: ${bidName.props.children[1]}`);
  };

  return (
    <Modal id="chartModal" title={`${date} Bid FeedBack`} openFunc={open} closeFunc={close}>
      <Modal.Body>
        <div className="align-self-center">
          <h3>Select a theme:</h3>
          {'    '}
          <Select
            defaultValue={themes[0]}
            name="themes"
            options={themes}
            onChange={(option) => { setTheme(option.value); }}
          />
        </div>
        <br />
        {(theme !== 'undefined' && currentData !== 'undefined') && (
        <Accordion allowMultipleExpanded preExpanded={[0]}>
          {currentData?.map((item) => (
            <AccordionItem uuid={item.uuid}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item.heading}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{item.content}</p>
                <Button type="button" color="primary" onClick={() => openBid({ ISC_REF: item.salesCloudReference, bidName: item.heading })}> Open Bid</Button>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" color="secondary" onClick={() => close.current()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

LineChartModal.propTypes = {
  modalData: PropTypes.shape.isRequired,
  themes: PropTypes.shape.isRequired,
  date: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default LineChartModal;

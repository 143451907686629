import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

function Accordion({ items }) {
  return (
    // Always expand the first item so that people realise that they have to open accordion items
    <ReactAccordion allowMultipleExpanded preExpanded={[items[0].uuid]}>
      {items.map((item) => (
        <AccordionItem uuid={item.uuid}>
          <AccordionItemHeading>
            <AccordionItemButton>
              {item.heading}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{item.content}</p>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </ReactAccordion>
  );
}

Accordion.defaultProps = {
  items: [{
    uuid: 'a',
    heading: 'First heading',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Veritatis, ab. Molestiaearum minus excepturi dolores ea? Corrupti,ea dignissimos alias saepe molestias illum ducimus deserunt.Eaque, explicabo! Facilis, dolorem maiores!',
  },
  {
    uuid: 'b',
    heading: 'Second heading',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Veritatis, ab. Molestiaearum minus excepturi dolores ea? Corrupti, ea dignissimosalias saepe molestias illum ducimus deserunt. Eaque, explicabo! Facilis, dolorem maiores!',
  },
  {
    uuid: 'c',
    heading: 'Third heading',
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Veritatis, ab. Molestiaearum minus excepturi dolores ea? Corrupti, ea dignissimosalias saepe molestias illum ducimus deserunt. Eaque, explicabo! Facilis, dolorem maiores!',
  }],
};

Accordion.propTypes = {
  items: PropTypes.shape,
};

export default Accordion;

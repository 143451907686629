import http from './http';
import toastItem from '../Components/presentation/toastItem';

const uploadFiles = async (closeFunc, fileObj) => {
  if (typeof fileObj === 'undefined') { toastItem(false, 'ERROR: no file selected'); return; }
  const file = fileObj.files[0]; // selecting only the first file
  const fd = new FormData(); // upload worked only when FormData is used
  fd.append('csvfile', file);

  if (typeof file !== 'undefined') {
    try {
      await http({
        method: 'POST',
        url: '/api/importcsv/post',
        data: fd,
        headers: { 'Content-Type': undefined }, // must to make the upload work
      });
      toastItem(true, 'SUCCESS: file imported!');
    } catch (err) {
      toastItem(false, 'ERROR: file was not imported');
    }
  }
  closeFunc.current();
};
export default uploadFiles;

import React from 'react';
import PropTypes from 'prop-types';

function InformationView({ bid }) {
  return (
    <div className="tab-content" id="bidTabContent">
      <div className="tab-pane fade show active p-2" id="info" role="tabpanel" aria-labelledby="info-tab">
        <h4 className="mt-2 mb-4">Information</h4>
        <dl className="row mt-2">
          <dt className="col-sm-3">Trello ID</dt>
          <dd className="col-sm-9">
            {bid.trelloId}
          </dd>
          <dt className="col-sm-3">Bid Name</dt>
          <dd className="col-sm-9">
            {bid.name}
          </dd>
          <dt className="col-sm-3">Bid Client</dt>
          <dd className="col-sm-9">
            {bid.client}
          </dd>
          <dt className="col-sm-3">Bid Year</dt>
          <dd className="col-sm-9">
            {new Date(bid.year).toLocaleDateString('en-GB')}
          </dd>
          <dt className="col-sm-3">Bid Status</dt>
          <dd className="col-sm-9">
            {bid.status}
          </dd>
          <dt className="col-sm-3">Bid Type</dt>
          <dd className="col-sm-9">
            {bid.type.map(
              (type) => (
                <span className="badge bg-secondary">
                  {type}
                </span>
              ),
            )}
          </dd>
          <dt className="col-sm-3">Bid Label</dt>
          <dd className="col-sm-9">
            {Object.keys(bid.bidLabels).map((key) => (
              <span
                className="badge"
                style={{ backgroundColor: bid.bidLabels[key].labelColor }}
              >
                {bid.bidLabels[key].labelName}
              </span>
            ))}
          </dd>
        </dl>
      </div>
    </div>
  );
}

InformationView.propTypes = {
  bid: PropTypes.instanceOf(Object).isRequired,
};

export default InformationView;

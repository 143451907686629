import React, { useState, useEffect } from 'react';
import '../RatesView.css';
import Header from '../Components/Header';
import LoadSpinner from '../Components/presentation/LoadSpinner';
import Toast from '../Components/presentation/Toast';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../Components/presentation/Button';
import Date from '../Components/presentation/Date';
import Text from '../Components/presentation/Text';
import Modal from '../Components/presentation/Modal';
import useGetRates from '../hooks/useGetRates';
import DeleteRate from '../services/deleteRate';
import EditRate from '../services/editRate';
import AddRateModal from '../Components/AddRateModal';

function Rates() {
  // set states
  const [isLoading, setIsLoading] = useState(true);
  const [rates, setRates] = useState([]);
  const [selectedRate, setSelectedRate] = useState({ _id: '', band: '' });
  const [selectedCost, setSelectedCost] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState('null');
  const [selectedEndDate, setSelectedEndDate] = useState('null');
  const [error, setError] = useState(null);

  const openFunc = React.useRef(null);
  const closeFunc = React.useRef(null);
  const openFuncDeleteRate = React.useRef(null);
  const closeFuncDeleteRate = React.useRef(null);
  const openAddRate = React.useRef(null);
  const closeAddRate = React.useRef(null);

  // make request to get rates from server
  const { response, err } = useGetRates({
    like: 'true',
  });

  const type = 'importe Rate';

  const handleModalOpen = (currentRate) => {
    setSelectedRate(currentRate);
    setSelectedCost(currentRate.cost);
    setSelectedStartDate(currentRate.startDate);
    setSelectedEndDate(currentRate.endDate);
    openFunc.current();
  };

  const deleteModalOpen = (currentRate) => {
    setSelectedRate(currentRate);
    openFuncDeleteRate.current();
  };

  // handle the response and error accordingly
  useEffect(() => {
    // check if error is due to unauthorize access
    if (typeof err === 'string' && err === 'Unauthorized') {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
    // check if the response has an error
    if (err != null) {
      setError(err);
    }

    // check if the response has contents
    if (response != null) {
      setRates(response);
      setIsLoading(false);
    }
  }, [response, err]);

  return (
    <div>
      <Header />
      <Toast />
      {isLoading && <LoadSpinner />}
      <h1> Rates </h1>
      <Button
        outline="outline-primary"
        type={type}
        style={{ margin: '5px' }}
        onClick={() => openAddRate.current()}
      >
        Add Rate
      </Button>
      <table>
        <thead>
          <tr>
            <th>Band</th>
            <th>Cost</th>
            <th>start Date</th>
            <th>end Date</th>
            <th> </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {rates.map((currentRate, index) => (
            <tr>
              <td value={index}>
                { currentRate.band }
              </td>
              <td value={index}>
                { currentRate.cost }
              </td>
              <td value={index}>
                { currentRate.startDate }
              </td>
              <td value={index}>
                { currentRate.endDate }
              </td>
              <td>
                <button
                  className="btn btn-link link-primary p-0"
                  title="Edit rate "
                  onClick={() => handleModalOpen(currentRate)}
                  type="submit"
                >
                  <i className="bi-pencil" />
                </button>
                <button
                  className="btn btn-link link-danger p-0"
                  // bs-data-toggle="tooltip"
                  title="Remove Rate"
                  type="submit"
                  onClick={() => deleteModalOpen(currentRate)}
                >
                  <i className="bi-x-square" />
                </button>
              </td>
              {' '}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="ratesModelContainer">
        <Modal id="editRate" title="Edit Rate" openFunc={openFunc} closeFunc={closeFunc}>
          <Modal.Body>
            <form>
              <label htmlFor="text" style={{ display: 'inline' }}>
                Band
                <br />
                <input className="form-control" value={selectedRate.band} readOnly />
              </label>
              <Text
                Type="Cost"
                className="form-control"
                required
                validationFn={(inputString) => {
                  if (inputString.trim().length) { return true; } return false;
                }}
                validationErrorMessage="Cost is required."
                placeholder="Cost      is a required field"
                value={selectedCost}
                setField={(data) => { setSelectedCost(data); }}
              >
                Cost
              </Text>
              <br />
              <Date
                type="startDate"
                className="form-control"
                value={selectedStartDate}
                setField={(data) => { setSelectedStartDate(data); }}
                validationErrorMessage="Start Date is required."
                style={{ width: '466px' }}
              >
                Start Date
              </Date>
              <br />
              <Date
                type="endDate"
                className="form-control"
                value={selectedEndDate}
                setField={(data) => { setSelectedEndDate(data); }}
                validationErrorMessage="End Date is required."
                style={{ width: '466px' }}
              >
                End Date
              </Date>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" color="secondary" onClick={() => closeFunc.current()}>Close</Button>
            <Button
              type="button"
              color="primary"
              onClick={async () => {
                await EditRate({
                  _id: selectedRate._id,
                  band: selectedRate.band,
                  cost: selectedCost,
                  startDate: selectedStartDate,
                  endDate: selectedEndDate,
                  setRates,
                  closeFunc,
                });
              }}
            >
              Update Rate

            </Button>
          </Modal.Footer>
        </Modal>
        <Modal id="deleteRate" title="Delete Rate" openFunc={openFuncDeleteRate} closeFunc={closeFuncDeleteRate}>
          <Modal.Body>
            <form id="deleteText">
              <label htmlFor="deleteLabel" style={{ display: 'inline', color: 'red' }}>
                <strong>Warning:</strong>
                {' '}
                This cannot be undone. All rate details will be permanently deleted
              </label>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" color="secondary" onClick={() => closeFuncDeleteRate.current()}>Cancel</Button>
            <Button
              type="button"
              color="danger"
              onClick={async () => {
                await DeleteRate({ _id: selectedRate._id, setRates, closeFuncDeleteRate });
              }}
            >
              Delete Rate
            </Button>
          </Modal.Footer>
        </Modal>
        <AddRateModal openAddRate={openAddRate} closeAddRate={closeAddRate} setRates={setRates} />
      </div>
      {error !== null && <div>Error in loading the Rates : </div> }
      <Toast />
    </div>
  );
}

export default Rates;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function SelectForm({
  items, labelFor, children, onChangeFn, setField,

}) {
  const [select, setSelect] = useState('');
  useEffect(() => {
    setField(items[parseInt(select, 10)]);
  }, [select]);
  const handleChange = (event) => {
    setSelect(event.target.value);
    onChangeFn();
  };

  return (
    <label htmlFor={labelFor} className="form-label" style={{ display: 'inline' }}>
      {children}
      <select
        value={select}
        onChange={handleChange}
        className="form-select"
        aria-label="Type"
      >

        {select === ''
        && <option value=""> </option> }
        {items.map((item, index) => (
          <option value={index}>{item}</option>
        ))}
      </select>
    </label>
  );
}

SelectForm.defaultProps = {
  items: ['value 1', 'value 2'],
  labelFor: '',
  setField: () => true,
  onChangeFn: () => {},

};

SelectForm.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  labelFor: PropTypes.string,
  setField: PropTypes.func,

  onChangeFn: PropTypes.func,
};

export default SelectForm;

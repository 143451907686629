import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';

const DashboardContext = createContext({});

export function DashboardContextProvider({ children }) {
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('---');
  const [searchName, setSearchName] = useState();
  const [bids, setBids] = useState();
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  function storeContext() {
    const DashboardContextStore = {
      limit,
      setLimit,
      offset,
      setOffset,
      bids,
      setBids,
      sortBy,
      setSortBy,
      searchName,
      setSearchName,
      isLoading,
      setLoading,
      currentPage,
      setCurrentPage,
    };
    return DashboardContextStore;
  }
  // useMemo is used here to fix an eslint problem with context changing every render
  const ContextStore = useMemo(() => storeContext(), [isLoading, bids]);

  return (
    <DashboardContext.Provider
      value={ContextStore}
    >
      {children}
    </DashboardContext.Provider>
  );
}

DashboardContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useDashboardContext = () => useContext(DashboardContext);

import http from './http';

async function getMember(ibmId) {
  let person;
  let error;

  try {
    person = await http({
      method: 'GET',
      url: '/api/person',
      params: {
        ibmId,
      },
    });
  } catch (err) {
    error = err;
  }
  return { person, error };
}

export default getMember;

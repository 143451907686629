import React from 'react';
import { Route, Link, Routes } from 'react-router-dom';

function NotFoundPage() {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/#/dashboard">Home</Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

function Home() {
  return <p>Home</p>;
}

function PageNotFound() {
  return (
    <div>
      <h1 style={{ textAlign: 'Center' }}>You have landed in unknown territory</h1>
      <h3 style={{ textAlign: 'Center' }}>
        Use this
        {' '}
        <Link to="/#/dashboard">Link</Link>
        {' '}
        to return back to the homepage
      </h3>
    </div>
  );
}

export default NotFoundPage;

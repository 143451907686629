import React from 'react';
import PropTypes from 'prop-types';

function Button({
  id, outline, color, children, type, onClick, style, image,
}) {
  const className = `btn btn${outline.length > 0 ? `-${outline}` : ''}${color.length > 0 ? `-${color}` : ''}`;
  return (
    // eslint-disable-next-line react/button-has-type
    <button id={id} type={type} className={className} onClick={onClick} style={style}>
      {children}
      {image === undefined ? null : <i className={image} />}
    </button>

  );
}

Button.defaultProps = {
  outline: '',
  color: '',
  type: 'button',
  onClick: () => {},
  style: {},
  image: '',
};

Button.propTypes = {
  id: PropTypes.string.isRequired,
  outline: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf,
  image: PropTypes.string,
};

export default Button;

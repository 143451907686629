const openBrowserWindow = (url) => {
  let finalURL = '';
  if (url.includes('http://') || url.includes('https://')) {
    finalURL = url;
  } else {
    finalURL = `https://${url}`;
  }
  window.open(finalURL);
};

export default openBrowserWindow;

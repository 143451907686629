import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../presentation/Button';
import Modal from '../presentation/Modal';
import addTeamMenber from '../../services/addTeamMember';

function AddTeamMemberModal({
  openAddTeamMember, closeAddTeamMember,
}) {
  const [ibmId, setIbmId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [band, setBand] = useState();
  const [hoursWorked, sethoursWorked] = useState();
  const [cost, setCost] = useState();
  const [department, setDepartment] = useState();

  const clearAllState = () => {
    setIbmId('');
    setName('');
    setEmail('');
    setBand('');
    sethoursWorked('');
    setCost('');
    setDepartment('');
  };

  return (
    <Modal id="AddTeamMember" title="New Team Member" openFunc={openAddTeamMember} closeFunc={closeAddTeamMember}>
      <form>
        <Modal.Body>
          <label htmlFor="ibmId" style={{ display: 'inline' }}>
            IBM ID
            <input type="text" className=" form-control" value={ibmId} onChange={(event) => { setIbmId(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="teamMember" style={{ display: 'inline' }}>
            Name
            <input type="text" className=" form-control" value={name} onChange={(event) => { setName(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="email" style={{ display: 'inline' }}>
            Email
            <input type="text" className=" form-control" value={email} onChange={(event) => { setEmail(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="band" style={{ display: 'inline' }}>
            Band
            <input type="text" className=" form-control" value={band} onChange={(event) => { setBand(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="hoursWorked" style={{ display: 'inline' }}>
            Hours Worked
            <input type="text" className=" form-control" value={hoursWorked} onChange={(event) => { sethoursWorked(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="cost" style={{ display: 'inline' }}>
            Cost
            <input type="text" className=" form-control" value={cost} onChange={(event) => { setCost(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="department" style={{ display: 'inline' }}>
            Department
            <input type="text" className=" form-control" value={department} onChange={(event) => { setDepartment(event.target.value); }} />
          </label>
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => { closeAddTeamMember.current(); clearAllState(); }}>Close</Button>
          <Button type="button" color="primary" onClick={async () => { await addTeamMenber(ibmId, name, email, band, hoursWorked, cost, department); clearAllState(); closeAddTeamMember.current(); }}>Add Team Member</Button>
        </Modal.Footer>
      </form>

    </Modal>
  );
}

AddTeamMemberModal.defaultProps = {};

AddTeamMemberModal.propTypes = {
  openAddTeamMember: PropTypes.func.isRequired,
  closeAddTeamMember: PropTypes.func.isRequired,
};

export default AddTeamMemberModal;

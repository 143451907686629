import http from './http';
import toastItem from '../Components/presentation/toastItem';
import getRates from './getRates';

const AddRate = async ({
  band, cost, startDate, endDate, setRates, closeAddRate,
}) => {
  try {
    await http({
      method: 'POST',
      url: '/api/rates',
      data: {
        band,
        cost,
        startDate: `${new Date(startDate).toLocaleDateString('en-US')}`,
        endDate: `${new Date(endDate).toLocaleDateString('en-US')} `,
      },
    });
    toastItem(true, 'Rate successfully added!');
    closeAddRate.current();
    const rateResponce = await getRates({
      like: 'true',
    });
    setRates(rateResponce.data);
  } catch (err) {
    toastItem(false, 'Rate failed to add!');
  }
};

export default AddRate;

import http from './http';
import toastItem from '../Components/presentation/toastItem';
import getRates from './getRates';

const DeleteRate = async ({ _id, setRates, closeFuncDeleteRate }) => {
  try {
    await http({
      method: 'DELETE',
      url: '/api/rates',
      params: {
        _id,
      },
    });
    toastItem(true, 'Rate successfully deleted!');
    closeFuncDeleteRate.current();
    const rateResponce = await getRates({
      like: 'true',
    });
    setRates(rateResponce.data);
  } catch (err) {
    toastItem(false, 'Rate failed to delete!');
  }
};

export default DeleteRate;

import http from './http';

async function putImportBid(type, _id, link) {
  let result;

  if (type === 'trello') {
    result = await http({
      method: 'PUT',
      url: '/api/trello/import-bid-trello',
      data: {
        _id,
        trelloLink: link,
      },
    });
  } else if (type === 'slack') {
    result = await http({
      method: 'PUT',
      url: '/api/slack/import-slack',
      data: {
        _id,
        slackLink: link,
      },
    });
  } else {
    result = await http({
      method: 'PUT',
      url: '/api/bid/import-bid',
      data: {
        _id,
        boxLink: link,
      },
    });
  }

  return result;
}

export default putImportBid;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'react-select';
import Button from '../presentation/Button';
import Modal from '../presentation/Modal';
import editQuestion from '../../services/editQuestion';

function EditQuestionModal({
  openEditQuestion, closeEditQuestion, question, bid, setBid, enumResponse, tagResponse,
}) {
  const [theme, setTheme] = useState(question.theme);
  const [tags, setTags] = useState(question.tags);
  const [rawScore, setRawScore] = useState(question.rawScore);
  const [highestScore, setHighestScore] = useState(question.rawHighest);
  const [percentage, setPercentage] = useState();
  const [assignee, setAssignee] = useState(question.assignee);
  const [clientFeedback, setClientFeedback] = useState(question.feedback);

  useEffect(() => {
    if (
      typeof rawScore !== 'undefined' && typeof highestScore !== 'undefined'
    ) {
      setPercentage(Math.round(
        (rawScore / highestScore) * 100,
      ));
    }
  }, [highestScore, rawScore]);
  useEffect(() => {
    setTheme(question.theme);
    setTags(question.tags);
    setRawScore(question.rawScore);
    setHighestScore(question.rawHighest);
    setAssignee(question.assignee);
    setClientFeedback(question.feedback);
  }, [question]);
  return (
    <Modal id="editQuestion" title="Edit Question" openFunc={openEditQuestion} closeFunc={closeEditQuestion}>
      <form id="editQuestionForm" name="editQuestionForm">
        <Modal.Body>
          <label htmlFor="questionReference" style={{ display: 'inline' }}>
            Question Reference
            <input type="text" className="form-control" value={question.reference} readOnly />
          </label>
          <br />
          <label htmlFor="theme" className="form-label" style={{ display: 'inline' }}>
            Theme
            <select
              className="form-select"
              value={theme}
              onChange={(event) => { setTheme(event.target.value); }}
            >
              <option disabled selected value> -- Select a Theme -- </option>
              {enumResponse[1].map((item) => (
                <option value={item} key={item}>{item}</option>
              ))}
            </select>
          </label>
          <br />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="tags" className="form-label" style={{ display: 'inline' }}>
            Tags
            <MultiSelect
              isMulti
              options={tagResponse.tags.map((item, index) => ({
                label: item,
                value: index,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              value={tags}
              onChange={(data) => setTags(data)}
            />
          </label>
          <br />
          <label htmlFor="rawScore" style={{ display: 'inline' }}>
            Raw Score
            <input type="number" className="form-control" value={rawScore} onChange={(event) => { setRawScore(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="rawHighest" style={{ display: 'inline' }}>
            Highest Available Score
            <input type="number" className="form-control" value={highestScore} onChange={(event) => { setHighestScore(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="percentage" style={{ display: 'inline' }}>
            Calculated Percentage
            <input type="number" className="form-control" value={percentage} readOnly />
          </label>
          <br />
          <label htmlFor="assignee" className="form-label" style={{ display: 'inline' }}>
            Assignee
            <select
              className="form-select"
              value={assignee}
              onChange={(event) => { setAssignee(event.target.value); }}
            >
              <option disabled selected value> -- Select an Assignee -- </option>
              {bid.team.map((item) => (
                <option value={item._id} key={item._id}>{item.name}</option>
              ))}
            </select>
          </label>
          <br />
          <label htmlFor="feedback" className="form-label">
            Client Feedback
            <textarea name="feedback" id="feedback" rows="5" cols="50" className="form-control" value={clientFeedback} onChange={(event) => { setClientFeedback(event.target.value); }} />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => closeEditQuestion.current()}>Close</Button>
          <Button
            type="button"
            color="primary"
            onClick={async () => {
              await editQuestion({
                _id: question._id,
                reference: question.reference,
                theme,
                selectedTags: tags,
                rawScore,
                rawHighest: highestScore,
                percentage,
                assignee,
                feedback: clientFeedback,
                bid,
                setBid,
                closeEditQuestion,
              });
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

EditQuestionModal.defaultProps = {
};

EditQuestionModal.propTypes = {
  openEditQuestion: PropTypes.func.isRequired,
  closeEditQuestion: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  bid: PropTypes.instanceOf(Object).isRequired,
  setBid: PropTypes.func.isRequired,
  enumResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditQuestionModal;

import React, { useState } from 'react';
import BidList from './BidList';
import { useDashboardContext } from '../Pages/DashboardContext';

function BidTable() {
  const [text, setText] = useState('');
  const {
    isLoading, setLoading, bids, sortBy, setSortBy, setSearchName, limit, setLimit,
  } = useDashboardContext();
  const sortByChangeHandler = (event) => {
    setSortBy(event.target.value);
    setLoading(true);
  };
  const onInputChange = (event) => {
    setText(event.target.value);
  };
  const limitChangeHandler = (event) => {
    setLimit(Number(event.target.value));
    setLoading(true);
  };

  const searchBidHandler = () => {
    setSearchName(text);
    setLoading(true);
  };
  if (isLoading) {
    return <div>bid Loading...</div>;
  }

  return (
    <div>
      <div className="row mt-4">
        <div className="col-12 col-md-4 offset-md-8">
          <div className="input-group">
            <input
              type="text"
              value={text}
              onChange={onInputChange}
              className="form-control"
              placeholder="Bid Name"
              aria-label="Bid Name"
              aria-describedby="search-button-addon"
            />
            <button className="btn btn-outline-secondary" type="submit" id="search-button-addon" onClick={searchBidHandler}>
              Search Bids
              {' '}
              <i className="bi-search" />
            </button>
          </div>
        </div>
        <div>
          <h3>Sort By:</h3>
          <select value={sortBy} onChange={sortByChangeHandler}>
            <option>---</option>
            <option>Date (Ascending)</option>
            <option>Date (Descending)</option>
            <option>ISC Ref. Alphabetically (Ascending)</option>
            <option>ISC Ref. Alphabetically (Descending)</option>
          </select>
          <h3>Items per page:</h3>
          <select value={limit} onChange={limitChangeHandler}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <div id="dashboard-sync-alert-fail" />
          <table className="table table-hover" id="bidTable">
            <thead>
              <tr>
                <th scope="col">ISC REF</th>
                <th scope="col">Name</th>
                <th scope="col">Client</th>
                <th scope="col">Year</th>
                <th scope="col">Status</th>
                <th scope="col">Type</th>
                <th scope="col">Label</th>
              </tr>
            </thead>
            {Object.keys(bids).map((key) => (
              <BidList
                ISC_REF={bids[key].salesCloudReference}
                Name={bids[key].name}
                Client={bids[key].client}
                Year={bids[key].year}
                Status={bids[key].status}
                Type={bids[key].type}
                Label={bids[key].bidLabels}
              />
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}
export default BidTable;

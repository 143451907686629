import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../presentation/Button';
import openBrowserWindow from '../../../services/openBrowserWindow';

function SpawnButtons({
  type, buttonText, bid, openFuncImport, openFuncDelete, openFuncCreate,
}) {
  let url;
  if (type === 'trello') {
    url = bid.bidTrelloURL;
  } else if (type === 'slack') {
    url = bid.bidSlackURL;
  } else if (type === 'box') {
    url = bid.bidBoxURL;
  }

  if ((url === '' || url === undefined) && (type === 'trello' || type === 'slack')) {
    return (
      <div className="m-5 text-center">
        <p>
          <Button id="importLink" type="button" outline="outline" color="primary" image="bi-download" onClick={() => openFuncImport.current()}>
            Import
            {buttonText}
            Link
            {' '}
          </Button>
        </p>
      </div>
    );
  } if ((url === '' || url === undefined) && type === 'box') {
    return (
      <div className="m-5 text-center">
        <p>
          <Button id="createBox" type="button" outline="outline" color="primary" image="bi-download" onClick={() => openFuncCreate.current()}>
            Create
            {buttonText}
            Folder
            {' '}
          </Button>
          {' '}
          or
          {' '}
          <Button id="importLink" type="button" outline="outline" color="primary" image="bi-cloud" onClick={() => openFuncImport.current()}>
            Import
            {buttonText}
            Link
            {' '}
          </Button>
        </p>
      </div>
    );
  }

  return (
    <div className="m-5 text-center">
      <Button id="access" type="button" color="primary" image="bi-folder-symlink" onClick={() => openBrowserWindow(url)}>
        Access
        {buttonText}
      </Button>
      <span style={{ margin: 2 }} />
      <Button id="editLink" type="button" outline="outline" color="primary" image="bi-download" onClick={() => openFuncImport.current()}>
        Edit
        {buttonText}
        Link
        {' '}
      </Button>
      <span style={{ margin: 2 }} />
      <Button id="deleteLink" type="button" outline="outline" color="danger" image="bi-x-octagon" onClick={() => openFuncDelete.current()}>
        Delete
        {buttonText}
      </Button>
    </div>
  );
}

SpawnButtons.propTypes = {
  type: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  bid: PropTypes.instanceOf(Object).isRequired,
  openFuncImport: PropTypes.func.isRequired,
  openFuncDelete: PropTypes.func.isRequired,
  openFuncCreate: PropTypes.func.isRequired,
};

export default SpawnButtons;

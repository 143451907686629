import React, { useState } from 'react';
import Modal from '../../Components/presentation/Modal';
import uploadFiles from '../../services/uploadFiles';

import 'react-toastify/dist/ReactToastify.css';

function ImportBids() {
  const openFunc = React.useRef(null);
  const closeFunc = React.useRef(null);

  const [fileObj, setFileObj] = useState();

  return (
    <div className="d-inline">
      <button
        className="btn btn-primary ms-1"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#importcsvModal"
        onClick={() => openFunc.current()}
      >
        Import bids
        <i className="bi-plus" />
      </button>
      <Modal
        title="Import csv"
        openFunc={openFunc}
        closeFunc={closeFunc}
      >
        <Modal.Body>
          <form>
            <input
              type="file"
              name="csvFile"
              className="form-control"
              accept=".csv"
              onChange={(e) => {
                setFileObj(e.target);
              }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => closeFunc.current()}
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary ms-1"
              onClick={async () => {
                await uploadFiles(closeFunc, fileObj);
              }}
            >
              Import csv File
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ImportBids;

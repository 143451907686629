import http from './http';
import getBid from './getBid';
import toastItem from '../Components/presentation/toastItem';

async function editPerson(editPersonModel, bid, setBid, selectedPersonHoursWorked) {
  try {
    const newBidsWorked = JSON.parse(JSON.stringify(editPersonModel.bidsWorked));
    newBidsWorked[bid.salesCloudReference] = selectedPersonHoursWorked;
    await http({
      method: 'PUT',
      url: '/api/person',
      data: {
        _id: editPersonModel._id,
        ibmId: editPersonModel.ibmId,
        name: editPersonModel.name,
        band: editPersonModel.band,
        department: editPersonModel.department,
        bidsWorked: newBidsWorked,
      },
    });
    const bidResponse = await getBid({
      reference: bid.salesCloudReference,
    });
    setBid(bidResponse.data);
    toastItem(true, `SUCCESS: Team Member ${editPersonModel.name} edited!`);
  } catch (error) {
    toastItem(false, 'ERROR: Call to database failed!');
  }
}

export default editPerson;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../presentation/Button';
import './TeamProfileView.css';
import removeMember from '../../services/TeamProfileButtons';
import Modal from '../presentation/Modal';
import editPerson from '../../services/EditPersonButton';
import exportTeam from '../../services/exportTeam';
import importTeamMembers from '../../services/importTeamMembers';

function TeamProfileView({
  bid, setBid,

}) {
  const [teamProfileView, setTeamProfileView] = useState();
  const [selectedPerson, setSelectedPerson] = useState({ ibmId: '', name: '', bidsWorked: {} });
  const [selectedPersonHoursWorked, setSelectedPersonHoursWorked] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [fileObj, setFileObj] = useState(null);
  const handleChange = (event) => setTeamProfileView(event.target.value);
  const type = 'TeamProfile';

  const openFunc = React.useRef(null);
  const closeFunc = React.useRef(null);
  const openCSVImport = React.useRef(null);
  const closeCSVImport = React.useRef(null);

  const handleModalOpen = (person) => {
    setSelectedPerson(person);
    setSelectedPersonHoursWorked(person.bidsWorked[bid.salesCloudReference]);
    openFunc.current();
  };

  useEffect(() => {
    let allHours = 0;
    for (let i = 0; i < bid.team.length; i += 1) {
      allHours += parseInt(bid.team[i].bidsWorked[bid.salesCloudReference], 10);
    }
    setTotalHours(allHours);
  }, [bid]);

  return (
    <div className="tab-content" id="bidTabContent">
      <div className="tab-pane fade show active p-2" id="info" role="tabpanel" aria-labelledby="info-tab">
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="mt-2">
              Team Profile
              <small className="text-muted fs-6 d-block ml-0">
                <span />
                {`${totalHours} `}
                Hours Worked
              </small>
            </h4>
          </div>
          <div className="col-12 col-md-6 text-align: right">
            <Button outline="outline-primary" type={type} style={{ margin: '5px' }} onClick={() => { openCSVImport.current(); }}>
              Import Team Members +
            </Button>
            <Button
              outline="outline-primary"
              style={{ margin: '5px' }}
              onClick={async () => { await exportTeam({ bid, bidTeam: bid.team }); }}
            >
              Export Team Members
              {' '}
              <i className="bi bi-download" />
            </Button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Band</th>
              <th>Hours Worked</th>
              <th>Cost</th>
              <th>Department</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {bid.team.map((person, index) => (
              <tr>
                <td value={index}>
                  { person.name }
                </td>
                <td value={index}>
                  { person.ibmId }
                </td>
                <td value={index}>
                  { person.band }
                </td>
                <td value={index}>
                  { person.bidsWorked[bid.salesCloudReference] }
                </td>
                <td value={index}>
                  £NaN
                </td>
                <td value={index}>
                  { person.department }
                </td>
                <td>
                  <button
                    className="btn btn-link link-primary p-0"
                    title="Edit team member"
                    value={teamProfileView}
                    onChange={handleChange}
                    onClick={() => handleModalOpen(person)}
                    type="submit"
                  >
                    <i className="bi-pencil" alt="Edit team member" />
                  </button>
                  <button
                    className="btn btn-link link-danger p-0"
                    // bs-data-toggle="tooltip"
                    title="Remove team member"
                    type="submit"
                    value={teamProfileView}
                    onChange={handleChange}
                    onClick={() => removeMember(person, bid, setBid)}
                  >
                    <i className="bi-x-square" alt="Remove team member" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal id="editPerson" title="Edit Person" openFunc={openFunc} closeFunc={closeFunc}>
          <Modal.Body>
            <form>
              <label htmlFor="text" style={{ display: 'inline' }}>
                IBM Email address
                <br />
                <input className="form-control" type="email" value={selectedPerson.ibmId} readOnly />
              </label>
              <label htmlFor="text" style={{ display: 'inline' }}>
                Person name
                <br />
                <input className="form-control" type="text" value={selectedPerson.name} readOnly />
              </label>
              <label htmlFor="text" style={{ display: 'inline' }}>
                Hours worked on project
                <br />
                <input className="form-control" value={selectedPersonHoursWorked} onChange={(event) => { setSelectedPersonHoursWorked(event.target.value); }} type="text" />
              </label>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" color="secondary" onClick={() => closeFunc.current()}>Close</Button>
            <Button type="button" color="primary" onClick={async () => { await editPerson(selectedPerson, bid, setBid, selectedPersonHoursWorked); closeFunc.current(); }}>Update Person</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          id="importCSVTeam"
          title="Import csv Team"
          openFunc={openCSVImport}
          closeFunc={closeCSVImport}
        >
          <Modal.Body>
            <p>
              Team members must be imported using a
              {' '}
              <strong>.csv</strong>
              {' '}
              file format for it to work correctly. An example of the format
              can be found
              {' '}
              <a href="../../../public/assets/importTeamExample.csv" target="_blank" download>here</a>
              .
            </p>
            <form>
              <input
                type="file"
                name="csvFile"
                className="form-control"
                accept=".csv"
                onChange={(e) => {
                  setFileObj(e.target);
                }}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              color="secondary"
              onClick={() => { setFileObj(null); closeCSVImport.current(); }}
            >
              Close
            </Button>
            <button
              type="submit"
              className="btn btn-primary ms-1"
              disabled={fileObj === null}
              onClick={async () => {
                await importTeamMembers({
                  closeCSVImport, fileObj, setFileObj, bid, setBid,
                });
              }}
            >
              Import csv File
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

TeamProfileView.propTypes = {
  bid: PropTypes.instanceOf(Object).isRequired,
  setBid: PropTypes.func.isRequired,
};

export default TeamProfileView;

import { useState, useEffect } from 'react';
import getTotalBids from '../services/getTotalBids';

export const useGetTotalBids = () => {
  const [totalBidsResponse, setResponse] = useState(undefined);
  const [totalBidsError, setError] = useState(undefined);

  const fetchData = async () => {
    try {
      const result = await getTotalBids({
      });
      setResponse(result.data.bidCount);
    } catch (oops) {
      setError(oops);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // execute once only

  return { totalBidsResponse, totalBidsError };
};

export default useGetTotalBids;

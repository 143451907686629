import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  // copied straight from original code with angular cut out
  // href added to link back to homepage
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
      <div className="container">
        <a className="navbar-brand" href="/dashboard">
          HOP
          {' '}
          <strong>
            Bid Metric
          </strong>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggle}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={`${isOpen ? 'show' : ''} collapse navbar-collapse`} id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link" aria-current="page">Dashboard</Link>
            </li>
            <li className="nav-item">
              <Link to="/insights" className="nav-link" aria-current="page">Insights</Link>
            </li>
            <li className="nav-item">
              <Link to="/rates" className="nav-link" aria-current="page">Rates</Link>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
            <li>
              <Link to="/" className="nav-link">Logout</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;

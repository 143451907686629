import http from './http';
import toastItem from '../Components/presentation/toastItem';

const createQuestion = async (
  reference,
  theme,
  selectedTags,
  rawScore,
  rawHighest,
  percentage,
  assignee,
  feedback,
) => {
  const tags = selectedTags.map((item) => item.label);

  try {
    await http({
      method: 'POST',
      url: '/api/question',
      data: {
        reference,
        theme,
        tags,
        rawScore: parseInt(rawScore, 10),
        rawHighest: parseInt(rawHighest, 10),
        percentage,
        assignee,
        feedback,
      },
    });
    toastItem(true, 'SUCCESS: Question created');
  } catch (err) {
    toastItem(false, 'ERROR: could not create question');
  }
};

export default createQuestion;

import toastItem from '../Components/presentation/toastItem';

const exportQuestion = async ({ bid, filteredQuestions }) => {
  try {
    const exportQuestionData = [];
    let key;
    let text;
    const colName = [
      'reference',
      'theme',
      'tags',
      'rawScore',
      'rawHighest',
      'percentage',
      'assignee',
      'feedback',
    ];

    for (let i = 0; i < filteredQuestions.length + 1; i += 1) {
      const row = [];

      for (let j = 0; j < colName.length; j += 1) {
        key = colName[j];

        if (i === 0) {
          row.push(colName[j]);
        } else if (i >= 1 && j === 2) {
          text = filteredQuestions[i - 1][key];
          row.push(`"${text}"`);
        } else if (i !== 0) {
          text = filteredQuestions[i - 1][key];
          row.push(text);
        }
      }

      exportQuestionData.push(row.join(','));
    }
    const csvFile = new Blob([exportQuestionData.join('\n')], {
      type: 'text/csv',
    });
    const downloadLink = document.createElement('a');

    const date = new Date();
    const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const dateStringUrl = dateString.toString();

    downloadLink.download = `${bid.salesCloudReference}-questions${dateStringUrl}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
    toastItem(true, `SUCCESS: questions exported to file ${downloadLink.download}`);
  } catch (err) {
    toastItem(false, 'ERROR: fail to export team');
  }
};

export default exportQuestion;

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

function LoadSpinner({ message, style }) {
  return (
    <div className={style.className}>
      <Spinner
        animation="border"
        role="status"
        style={{ width: style.width, height: style.height }}
      >
        <span className="visually-hidden mb3">{message}</span>
      </Spinner>
      <h3>{message}</h3>
    </div>
  );
}

LoadSpinner.defaultProps = {
  message: 'Loading...',
  style: {
    width: '11rem',
    height: '11rem',
    className: 'd-flex flex-column min-vh-100 justify-content-center align-items-center',
  },
};

LoadSpinner.propTypes = {
  message: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
  }),
};

export default LoadSpinner;

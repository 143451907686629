import React, { useState, useEffect } from 'react';
// bootstrap
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
// local components
import Header from '../Components/Header';
import LineChart from '../Components/insights/LineChart/LineChart';
import BarChart from '../Components/insights/BarChart/BarChart';
import useGetCharts from '../hooks/useGetCharts';
import LoadSpinner from '../Components/presentation/LoadSpinner';

function Insights() {
  // set states
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});

  // make request to get bids from server
  const { useGetChartsResponse, useGetChartsError } = useGetCharts({
    like: 'true',
  });

  // handle the response and error accordingly
  useEffect(() => {
    // check if the response has an error
    if (useGetChartsError != null) {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }

    // check if the response has contents
    if (useGetChartsResponse != null) {
      setChartData(useGetChartsResponse);
      setIsLoading(false);
    }
  });

  // get the data necessary for the charts from the state bidsCharts
  const {
    lineOptions, lineData, barOptions, barData, questionsArray,
  } = chartData;
  return (
    <div>
      <Header />
      {isLoading && <LoadSpinner />}
      {!isLoading && (
      <div style={{
        position: 'relative', height: '40vh', width: '95vw', margin: '0 auto',
      }}
      >
        <Tab.Container id="insight-tab" defaultActiveKey="question">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column text-center">
                <Nav.Item>
                  <Nav.Link eventKey="question">Bid Question Score</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="status">Bid Status</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="question">
                  <LineChart
                    chartData={lineData}
                    options={lineOptions}
                    questionsArray={questionsArray}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="status">
                  <BarChart chartData={barData} options={barOptions} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      )}
    </div>
  );
}

export default Insights;

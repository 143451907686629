import http from './http';
import getBid from './getBid';
import toastItem from '../Components/presentation/toastItem';

const createBoxFolder = async (department, setBid, bid, closeFuncCreate, show) => {
  if (typeof department === 'undefined') {
    document.getElementById('boxError').style.visibility = 'visible';
  } else {
    show.current();
    document.getElementById('boxError').style.visibility = 'hidden';
    try {
      document.getElementById('submitBtn').disabled = 'disabled';
      await http({
        method: 'POST',
        url: '/api/bid/box-create-api',
        data: {
          _id: bid._id,
          bid_name: bid.name,
          bid_department: department,
          bid_sales_id: bid.salesCloudReference,
        },
      });
      const bidResponse = await getBid({
        reference: bid.salesCloudReference,
      });
      setBid(bidResponse.data);

      toastItem(true, 'SUCCESS: Box folder created');
    } catch (err) {
      toastItem(false, 'ERROR: could not create box folder');
    }
    closeFuncCreate.current();
  }
};

export default createBoxFolder;

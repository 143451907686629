import React from 'react';
import { ToastContainer } from 'react-toastify';

function Toast() {
  return (
    <ToastContainer
      position="bottom-right" // bottom-right
      autoClose={5000} // 500
      hideProgressBar={false} // false
      newestOnTop={false} // false
      closeOnClick // true
      rtl={false} // false
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

export default Toast;

import toastItem from '../Components/presentation/toastItem';

const exportTeam = async ({ bid, bidTeam }) => {
  try {
    const headers = ['Name',
      'Email',
      'Band',
      'Hours Worked',
      'Cost',
      'Department,'];

    let csvRows = [];

    const headerValues = headers.map((header) => (header));
    csvRows.push(headerValues);
    const teamArray = bidTeam.map((person) => (
      [`${person.name}`, `${person.ibmId}`, `${person.band === undefined ? '' : person.band}`, `${person.bidsWorked[bid.salesCloudReference]}`,
        '£NaN', `${person.department},`]));

    csvRows = csvRows.concat(teamArray);

    const csvFile = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    downloadLink.download = `team-profile-${bid.salesCloudReference}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    toastItem(true, `SUCCESS: team exported to file ${downloadLink.download}`);
  } catch (err) {
    toastItem(false, 'ERROR: fail to export team');
  }
};

export default exportTeam;

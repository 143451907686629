import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs, TabList, TabPanel,
}
  from 'react-tabs';

function TabComponent({
  name, items, children,
}) {
  const [tabComponent, setTabComponent] = useState('');
  const handleChange = (event) => setTabComponent(event.target.value);
  return (
    <div>
      <Tabs className="nav nav-tabs" role="tablist" value={tabComponent} onChange={handleChange}>
        <TabList className="nav-item" role="presentation">
          {items.map((item) => (
            <Tab
              eventKey={items}
              className="nav-link d-inline"
              Name={name}
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls={items}
              aria-selected="false"
            >
              {item}
            </Tab>
          ))}
        </TabList>
        {children.map((child, index) => (
          <TabPanel
            className="d-inline-block col-12"
            value={index}
          >
            {child}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
}
TabComponent.defaultProps = {
  items: ['value 1', 'value 2'],
  name: '',
};
TabComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default TabComponent;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

function Text({
  id,
  name,
  type,
  labelFor,
  placeholder,
  children,
  validationFn,
  validationErrorMessage,
  setField,
  onChangeFn,
  disabled,
}) {
  const [box, setBox] = useState('');
  const [errorDisplay, useErrorDisplay] = useState('none');

  useEffect(() => {
    setField(box);
  }, [box]);

  function requiredValidation() {
    if (validationFn(box)) {
      useErrorDisplay('none');
    } else {
      useErrorDisplay('block');
    }
  }

  function handleChange(event) {
    const updatedBox = event.target.value;
    setBox(updatedBox);
    requiredValidation();
    onChangeFn();
  }

  return (
    <label htmlFor={labelFor} className="form-label" style={{ display: 'inline' }}>
      {children}
      <input
        type={type}
        value={box}
        onChange={handleChange}
        onBlur={requiredValidation}
        name={name}
        id={id}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
      />
      <Alert variant="danger" style={{ display: errorDisplay }}>
        <i className="bi bi-exclamation-circle" />
        {validationErrorMessage}
      </Alert>
    </label>
  );
}
Text.defaultProps = {
  id: '',
  name: '',
  labelFor: '',
  placeholder: '',
  validationFn: () => true,
  onChangeFn: () => {},
  validationErrorMessage: 'Validation Error!',
  setField: () => true,
  disabled: false,
};

Text.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  labelFor: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node.isRequired,
  validationFn: PropTypes.func,
  onChangeFn: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  type: PropTypes.string.isRequired,
  setField: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Text;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import {
  Chart as ChartJS, registerables,
} from 'chart.js';

import LineChartModal from './LineChartModal';
import createThemeData from './createThemeData';

ChartJS.register(...registerables);

function LineChart({ chartData, options, questionsArray }) {
  const [themes, setThemes] = useState([]);
  const [identifiedPoints, setIdentifiedPoints] = useState([]);
  const [modalData, setModalData] = useState({});
  const [date, setDate] = useState('');

  // chart
  const chartRef = useRef(null);

  // useRef functions used to open and close modal
  const openModelFunc = useRef(null);
  const closeModelref = useRef(null);

  // this runs whenever a user clicks an intersection
  useEffect(() => {
    // prepare all the data that will be displayed in the modal
    if (identifiedPoints.length !== 0) {
      let selectedDate = '';
      const preparedData = {};
      for (let index = 0; index < themes.length; index += 1) {
        const currentTheme = themes[index].value;
        const data = createThemeData({
          identifiedPoints, chartData, questionsArray, themeIndex: index,
        });
        preparedData[currentTheme] = data;
        selectedDate = data[0].date;
      }
      // set the data
      setModalData({ ...preparedData });

      // set date
      setDate(selectedDate);

      // open modal
      openModelFunc.current();
    }
  }, [identifiedPoints]);

  // handle click on theme graph
  const handleClick = (event) => {
    const { current: chart } = chartRef;

    // if the event triggered is not about a chart, then do nothing.
    if (!chart) {
      return;
    }

    // clickedPoint will have length of more than zero only
    // if clicked on a valid datapoint on the chart
    const clickedPoints = getElementAtEvent(chart, event);

    // onClick triggers an event whenever anywhere in the chart is clicked
    // only intersections have clicked point(s) and if its not intersection, we dont do a thing
    // visit the react chart library and read about events
    if (clickedPoints.length === 0) {
      return;
    }

    // then the event was truggered by an intersection and preserve the
    // points in a state to be accessed elsewhere
    setIdentifiedPoints(clickedPoints);

    // prepare the theme list shownn in the modal in the select tag
    const themesArray = [];
    for (let i = 0; i < clickedPoints.length; i += 1) {
      const value = chartData.datasets[clickedPoints[i].datasetIndex].label;
      const themeObject = {
        value,
        label: value,
      };

      themesArray.push(themeObject);
      // themeArray.push(chartData.datasets[clickedPoints[i].datasetIndex].label);
    }

    // set the themelist to a state so that we can access it in the html side
    setThemes(themesArray);
  };

  return (
    <>
      <Line
        ref={chartRef}
        data={chartData}
        options={options}
        onClick={handleClick}
      />
      <LineChartModal
        themes={themes}
        modalData={modalData}
        date={date}
        identifiedPoints={identifiedPoints}
        questionsArray={questionsArray}
        chartData={chartData}
        open={openModelFunc}
        close={closeModelref}
      />
    </>
  );
}

LineChart.defaultProps = {
  chartData: {},
  options: {},
  questionsArray: {},
};

LineChart.propTypes = {
  chartData: PropTypes.shape,
  options: PropTypes.shape,
  questionsArray: PropTypes.shape,
};

export default LineChart;

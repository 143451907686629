import http from './http';

async function getTags() {
  const result = await http({
    method: 'GET',
    url: '/api/question/tags',
  });
  return result;
}
export default getTags;

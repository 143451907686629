import { useState, useEffect } from 'react';
import getBid from '../services/getBid';

export const useGetBid = ({
  limit, offset, sortby, name, like, reference,
}) => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState(undefined);

  const fetchData = async () => {
    try {
      const result = await getBid({
        like,
        name,
        limit,
        offset,
        sortby,
        reference,
      });
      setResponse(result.data);
    } catch (oops) {
      setError(oops);
    }
  };

  useEffect(() => {
    fetchData();
  }, [like, name, limit, offset, sortby, reference]); // execute once only

  return { response, error };
};

export default useGetBid;

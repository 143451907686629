import React from 'react';

function Login() {
  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <h1 className="display-1">HOP Bid Metrics</h1>
      <a className="btn btn-primary mt-3" href="/login">
        Login with w3id
        <i className="bi-box-arrow-in-right" />
      </a>
    </div>
  );
}

export default Login;

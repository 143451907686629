import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

function DropDownButton({
  outline, color, dropDownText, items,
}) {
  const ol = outline.length > 0 ? `${outline}-` : '';
  const cl = color.length > 0 ? color : '';
  const ollc = outline.length > 0 ? `${outline.toLowerCase()}-` : '';
  const cllc = color.length > 0 ? color.toLowerCase() : '';
  const itl = dropDownText.length > 0 ? dropDownText : '';

  return (
    <DropdownButton
      as={ButtonGroup}
      key={ol + cl}
      id={`dropdown-variants-${ol + cl}`}
      variant={ollc + cllc}
      title={itl}
    >
      {items.map(
        (menuItem, index) => {
          if (menuItem.name === 'divider') {
            return <Dropdown.Divider />;
          }
          return (
            <Dropdown.Item
              style={menuItem.style}
              eventKey={index + 1}
              onClick={() => menuItem.action()}
            >
              {menuItem.name}
            </Dropdown.Item>
          );
        },
      )}
    </DropdownButton>
  );
}

DropDownButton.defaultProps = {
  outline: '',
  color: 'secondary',
  dropDownText: 'Filler',
  items: [{
    name: 'Filler1',
    style: {},
    action: () => {},
  }, {
    name: 'Filler2',
    style: {},
    action: () => {},
  }],
};

DropDownButton.propTypes = {
  outline: PropTypes.string,
  color: PropTypes.string,
  dropDownText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string, PropTypes.object, PropTypes.func)),
};

export default DropDownButton;

import Papa from 'papaparse';
import getMember from './getMember';
import toastItem from '../Components/presentation/toastItem';
import updateBidMember from './updateBidMember';
import updateBid from './updateBid';

const importTeamMembers = async ({
  closeCSVImport, fileObj, setFileObj, bid, setBid,
}) => {
  // checks if file was selected
  if (fileObj === null) {
    toastItem(false, 'ERROR: no file selected');
    return;
  }

  // .files property returns an array that has the first element as the imported file
  const file = fileObj.files[0];

  const csvToJSON = async (csvData) => new Promise((resolve, reject) => {
    Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data) {
          resolve(results.data);
        } else {
          reject(results.errors);
        }
      },
    });
  });

  if (typeof file !== 'undefined') {
    try {
      const importedMembers = await csvToJSON(file);

      const getMemberPromises = [];
      for (let i = 0; i < importedMembers.length; i += 1) {
        getMemberPromises.push(getMember(importedMembers[i].Email));
      }

      const retrievedMembers = await Promise.allSettled(getMemberPromises);
      const updateBidMemberPromises = [];
      const updatedBid = {
        team: [],
      };
      const unfoundPeople = [];
      for (let i = 0; i < retrievedMembers.length; i += 1) {
        if (retrievedMembers[i].status === 'fulfilled') {
          const personData = retrievedMembers[i].value.person.data[0];
          updateBidMemberPromises.push(updateBidMember({
            _id: personData._id,
            // only updating hours worked for the selected bid
            bidsWorked: {
              ...personData.bidsWorked,
              [bid.salesCloudReference]: importedMembers[i].Hours_Worked,
            },
          }));
        } else {
          // save the people that have not been found
          unfoundPeople.push(importedMembers[i].Name);
        }
      }

      const updatedBidMembers = await Promise.allSettled(updateBidMemberPromises);

      const notUpdatedMembers = [];
      for (let i = 0; i < updatedBidMembers.length; i += 1) {
        let person;
        if (updatedBidMembers[i].status === 'fulfilled') {
          person = updatedBidMembers[i].value.result.data;
          // record people that have been updated and are to be added to the bid
          updatedBid.team.push(person._id);
        } else {
          notUpdatedMembers.push(person._id);
        }
      }

      const existingMembers = bid.team.map((member) => member._id);

      /*
      this is doing 2 things, adding the updated team members and
      already existing team members together. then filtering the new
      array that all contains _id(s) so that we dont have duplicate members
      */
      updatedBid.team = [...new Set([...updatedBid.team, ...existingMembers])];

      // prepare final bid to be sent to database
      const newBid = { ...bid, team: updatedBid.team };
      // update bid
      const finalUpdatedBid = await updateBid(newBid);

      // verify if bid was updated or not
      if (finalUpdatedBid.updatedBid) {
        setBid(finalUpdatedBid.updatedBid.data);
      }
      closeCSVImport.current();
      setFileObj(null);
      toastItem(true, 'SUCCESS: team members imported from file!');
    } catch (err) {
      closeCSVImport.current();
      setFileObj(null);
      toastItem(false, 'ERROR: file was not imported');
    }
  }
};
export default importTeamMembers;

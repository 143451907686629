import http from './http';
import getBid from './getBid';
import toastItem from '../Components/presentation/toastItem';

const editQuestion = async ({
  _id,
  reference,
  theme,
  selectedTags,
  rawScore,
  rawHighest,
  percentage,
  assignee,
  feedback,
  bid,
  setBid,
  closeEditQuestion,
}) => {
  const tags = selectedTags.map((item) => item.label);

  try {
    await http({
      method: 'PUT',
      url: '/api/question',
      data: {
        _id,
        reference,
        theme,
        tags,
        rawScore: parseInt(rawScore, 10),
        rawHighest: parseInt(rawHighest, 10),
        percentage,
        assignee,
        feedback,
      },
    });
    closeEditQuestion.current();
    const bidResponse = await getBid({
      reference: bid.salesCloudReference,
    });
    setBid(bidResponse.data);
    toastItem(true, 'SUCCESS: Question created');
  } catch (err) {
    toastItem(false, 'ERROR: could not update question');
  }
};

export default editQuestion;

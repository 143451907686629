import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditBid from '../../services/editBid';
import Button from '../presentation/Button';
import Modal from '../presentation/Modal';

function SpawnEditBidModal({
  openFuncEditBid, bid, enumResponse, setBid,
}) {
  const [nameInput, setNameInput] = useState(bid.name);
  const [clientInput, setClientInput] = useState(bid.client);
  const [yearInput, setYearInput] = useState(new Date(bid.year).toLocaleDateString('fr-CA'));
  const [statusInput, setStatusInput] = useState(bid.status);
  const [typeInput, setTypeInput] = useState(bid.type);

  const closeFuncEditBid = React.useRef(null);

  const items = [
    'AMS',
    'DATA',
    'DIGITAL',
    'PLATFORM',
    'STAFF AUGMENTATION',
    'SOLUTION DEVELOPMENT',
  ];

  const handleChange = (e, type) => {
    if (type === 'name') {
      setNameInput(e.target.value);
    } if (type === 'client') {
      setClientInput(e.target.value);
    } if (type === 'year') {
      setYearInput(e.target.value);
    } if (type === 'status') {
      setStatusInput(e.target.value);
    } if (type === 'type') {
      setTypeInput(e.target.value);
    }
  };

  return (
    <Modal id="editBid" title="Edit Bid" openFunc={openFuncEditBid} closeFunc={closeFuncEditBid}>
      <Modal.Body>
        <form id="deleteText">
          <label htmlFor="SCRLabel" style={{ display: 'inline' }}>
            Sales Cloud Reference
            <br />
            <input disabled="disabled" className="form-control" id="SCRLabel" value={bid.salesCloudReference} />
          </label>
          <label htmlFor="nameLabel" style={{ display: 'inline' }}>
            Name
            <br />
            <input value={nameInput} onChange={(e) => handleChange(e, 'name')} className="form-control" id="nameLabel" placeholder={bid.name} />
          </label>
          <label htmlFor="clientLabel" style={{ display: 'inline' }}>
            Client
            <br />
            <input value={clientInput} onChange={(e) => handleChange(e, 'client')} className="form-control" id="clientLabel" placeholder={bid.client} />
          </label>
          <label htmlFor="yearLabel" style={{ display: 'inline' }}>
            Year
            <br />
            <input type="date" value={yearInput} onChange={(e) => handleChange(e, 'year')} className="form-control" id="yearLabel" placeholder={bid.year} />
          </label>
          <label htmlFor="statusLabel" style={{ display: 'inline' }}>
            Status
            <br />
            <select className="form-control" onChange={(e) => handleChange(e, 'status')}>
              {enumResponse.map(
                (status) => {
                  if (status === bid.status) {
                    return (
                      <option
                        selected
                        value={status}
                      >
                        {status}
                      </option>
                    );
                  }
                  return (
                    <option
                      value={status}
                    >
                      {status}
                    </option>
                  );
                },
              )}
            </select>
          </label>
          <label htmlFor="typeLabel" style={{ display: 'inline' }}>
            Type
            <br />
            <select
              className="form-control"
              onChange={(e) => handleChange(e, 'type')}
              multiple
            >
              {items.map(
                (item) => {
                  if (bid.type !== undefined && JSON.stringify(bid.type).includes(item)) {
                    return (
                      <option
                        selected
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  }
                  return (
                    <option
                      value={item}
                    >
                      {item}
                    </option>
                  );
                },
              )}
            </select>
          </label>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" color="secondary" onClick={() => closeFuncEditBid.current()}>Close</Button>

        <Button
          type="button"
          color="primary"
          onClick={async () => {
            await EditBid({
              bid,
              nameInput,
              clientInput,
              yearInput,
              statusInput,
              typeInput,
              closeFuncEditBid,
              setBid,
            });
          }}
        >
          Edit Bid

        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SpawnEditBidModal.propTypes = {
  bid: PropTypes.instanceOf(Object).isRequired,
  enumResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
  openFuncEditBid: PropTypes.func.isRequired,
  setBid: PropTypes.func.isRequired,
};

export default SpawnEditBidModal;

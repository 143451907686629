import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function BidList({
  ISC_REF, Name, Client, Year, Status, Type, Label,
}) {
  const navigate = useNavigate();
  const bidShowHandler = () => {
    navigate('../bidview', { replace: true, state: { ISC_REF } });
  };

  return (
    <tbody>
      <tr onClick={bidShowHandler}>
        <td>{ ISC_REF }</td>
        <td>{ Name }</td>
        <td>{ Client }</td>
        <td>{ new Date(Year).toLocaleDateString() }</td>
        <td>{ Status }</td>
        <td>
          <span className="badge bg-secondary">{ Type }</span>
        </td>
        <td>
          {Object.keys(Label).map((key) => (
            <span
              className="badge ms-1"
              style={{ backgroundColor: Label[key].labelColor }}
            >
              { Label[key].labelName}
            </span>
          ))}
        </td>
      </tr>
    </tbody>
  );
}

BidList.propTypes = {
  ISC_REF: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Client: PropTypes.string.isRequired,
  Year: PropTypes.string.isRequired,
  Status: PropTypes.string.isRequired,
  Type: PropTypes.arrayOf(PropTypes.number).isRequired,
  Label: PropTypes.shape({
    labelName: PropTypes.string.isRequired,
    labelColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default BidList;

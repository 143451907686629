const prepareFeedbackDisplayData = (dtThemeFeedbackData, clkDate) => {
  const feedBackData = [];
  const uniqueBidNames = [...new Set(dtThemeFeedbackData.map((item) => item.bidName))];

  // for each bidName, construct the feedback
  // eslint-disable-next-line no-restricted-syntax
  for (const count in uniqueBidNames) {
    if (Object.prototype.hasOwnProperty.call(uniqueBidNames, count)) {
      const bName = uniqueBidNames[count];
      /* retrieve the bids and feedback for the current bidName
        filter the feedbackArray to retrieve only the objects
         which have matching bidName as the current item */

      const bidFeedBackArray = dtThemeFeedbackData.filter((el) => el.bidName === bName);
      const { salesCloudReference } = dtThemeFeedbackData[count];

      const displayFeedback = [];

      // construct the array of feedbacks for displaying
      bidFeedBackArray.forEach((bid) => {
        displayFeedback.push({
          percent: bid.percentage,
          feedback: bid.feedback,
        });
      });

      // construct the data to be displayed in modal as per view
      // add the date of the intersected point to the object so that its accesesed and used
      // in the modal's header
      const feedBackObj = {
        bidName: bName, feedbackData: displayFeedback, date: clkDate, salesCloudReference,
      };
      // push the name and feedback into the display Array
      feedBackData.push(feedBackObj);
    }
  }
  return feedBackData;
};

// get month number when passed a date string
const monthNumberFromString = (str) => new Date(`${str}`).getMonth() + 1;

// creates the theme data based on the theme selected
const createThemeData = ({
  identifiedPoints, chartData, questionsArray, themeIndex,
}) => {
  const clickedDatasetIndex = identifiedPoints[themeIndex].datasetIndex;
  const clickedElementIndex = identifiedPoints[themeIndex].index;
  const clickedDatasetPoint = chartData.datasets[clickedDatasetIndex];
  const clkTheme = clickedDatasetPoint.label;
  const clkDate = chartData.labels[clickedElementIndex];
  // clicked date is constructed in the format yyyy-mm to filter respective bids from the data array
  const filterDate = `${clkDate.substr(-4, 4)}-${monthNumberFromString(`1 ${clkDate}`)}`;
  // filter the qtnsArray to retrieve only the objects which have
  // matching theme as the clicked option
  const themeFilteredFeedback = questionsArray.filter((el) => el.theme === clkTheme);
  // filter the the retrieved theme array for the clicked month/year
  const dateThemeFilteredFeedback = themeFilteredFeedback.filter(
    (el) => el.bidDate.substr(0, filterDate.length) === filterDate,
  );
  // sort the feedback Array by bidName
  dateThemeFilteredFeedback.sort((a, b) => a.bidName.localeCompare(b.bidName));
  // display data
  const feedBackData = prepareFeedbackDisplayData(dateThemeFilteredFeedback, clkDate);

  return feedBackData;
};

export default createThemeData;

const createStatusData = ({ clickDate, bids, clickedStatus }) => {
  const quarter = clickDate.slice(0, 2);
  const quarters = {
    Q1: ['01', '02', '03'],
    Q2: ['04', '05', '06'],
    Q3: ['07', '08', '09'],
    Q4: ['10', '11', '12'],
  };
  const clickedMonths = quarters[quarter];
  const clickYear = clickDate.slice(3, 7);
  const statusFeedBackData = [];

  // adds bid data for the selected status
  clickedMonths.forEach((month) => {
    bids.forEach((bid) => {
      // check if the bid in question has the same status and has a year as well
      if (bid.status === clickedStatus && bid.year !== null) {
        const potentialYear = bid.year.slice(0, 4);
        const potentialMonth = bid.year.slice(5, 7);
        if (potentialYear === clickYear && potentialMonth === month) {
          statusFeedBackData.push({
            bidName: bid.name,
            client: bid.client,
            salesCloudReference: bid.salesCloudReference,
          });
        }
      }
    });
  });

  return statusFeedBackData;
};

export default createStatusData;

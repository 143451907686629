import http from './http';
import toastItem from '../Components/presentation/toastItem';
import getBid from './getBid';

const getTrelloBidIdDashboard = (bids) => {
  const trelloBidIdDashboard = [];

  Object.keys(bids).forEach((i) => {
    if (bids[i].trelloId) {
      trelloBidIdDashboard.push({
        trelloCardId: bids[i].trelloId,
        _id: bids[i]._id,
      });
    }
  });

  return trelloBidIdDashboard;
};

const getIdTrelloCards = (response) => {
  // Need to get idList from response and compare it to trelloBidIdDashboard
  const idTrelloCards = [];

  Object.keys(response).forEach((j) => {
    const card = response[j];

    Object.keys(card).forEach((x) => {
      idTrelloCards.push(card[x]);
    });
  });
  return idTrelloCards;
};

// SyncTrello function
const SyncTrello = async () => {
  try {
    const Getrequest = await http({
      method: 'GET',
      url: 'api/trello/get',
    });
    const idTrelloCards = getIdTrelloCards(Getrequest.data);
    const bids = await getBid({ like: 'true' });
    const trelloBidIdDashboard = getTrelloBidIdDashboard(bids.data);
    await Promise.all(idTrelloCards.map(async (y) => {
      await Promise.all(trelloBidIdDashboard.map(async (t) => {
        if (y.id === t.trelloCardId) {
          await http({
            method: 'PUT',
            url: '/api/bid/updateTrelloBid',
            data: {
              trelloBid: y,
              bidDashboard: t,
            },
          });
        }
      }));
    }));
    toastItem(true, 'Trello successfully synced!');
  } catch (err) {
    toastItem(false, 'Trello failed to sync!');
  }
};

export default SyncTrello;

import toastItem from '../Components/presentation/toastItem';
import http from './http';
import getBid from './getBid';

const removeQuestion = async ({
  bid, question, setBid, closeFuncDeleteQuestion,
}) => {
  const questionIndex = bid.questions.indexOf(question);
  bid.questions.splice(questionIndex, 1);
  try {
    await http({
      method: 'PUT',
      url: '/api/bid',
      data: {
        bid,
      },
    });
    closeFuncDeleteQuestion.current();
    const bidResponse = await getBid({
      reference: bid.salesCloudReference,
    });

    setBid(bidResponse.data);
    toastItem(true, `SUCCESS: Question: ${question.reference} removed!`);
  } catch (err) {
    toastItem(false, 'ERROR: Call to database failed!');
  }
};

export default removeQuestion;

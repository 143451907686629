import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'react-select';
import Button from '../presentation/Button';
import Modal from '../presentation/Modal';
import createQuestion from '../../services/createQuestion';

function AddQuestionModal({
  // eslint-disable-next-line react/prop-types
  member, openAddQuestion, closeAddQuestion, enumResponse, tagResponse,
}) {
  const [reference, setReference] = useState();
  const [theme, setTheme] = useState();
  const [selectedTags, setTags] = useState();
  const [rawScore, setRawScore] = useState();
  const [rawHighest, setRawHighest] = useState();
  const [percentage, setPercentage] = useState();
  const [assignee, setAssignee] = useState();
  const [feedback, setFeedback] = useState();

  useEffect(() => {
    if (
      typeof rawScore !== 'undefined' && typeof rawHighest !== 'undefined'
    ) {
      setPercentage(Math.round(
        (rawScore / rawHighest) * 100,
      ));
    }
  }, [rawHighest, rawScore]);

  const clearAllfield = () => {
    setReference();
    setTheme();
    setTags();
    setRawScore();
    setRawHighest();
    setPercentage();
    setAssignee();
    setFeedback();
  };

  return (
    <Modal id="addQuestion" title="New Question" openFunc={openAddQuestion} closeFunc={closeAddQuestion}>
      <form id="addQuestionForm" name="addQuestionForm">
        <Modal.Body>
          <label htmlFor="questionReference" style={{ display: 'inline' }}>
            Question Reference
            <input type="text" className="form-control" value={reference} onChange={(event) => { setReference(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="theme" className="form-label" style={{ display: 'inline' }}>
            Theme
            <select
              className="form-select"
              value={theme}
              onChange={(event) => { setTheme(event.target.value); }}
            >
              <option disabled selected value> -- Select a Theme -- </option>
              {enumResponse[1].map((item) => (
                <option value={item} key={item}>{item}</option>
              ))}
            </select>
          </label>
          <br />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="tags" className="form-label" style={{ display: 'inline' }}>
            Tags
            <MultiSelect
              isMulti
              options={tagResponse.tags.map((item, index) => ({
                label: item,
                value: index,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectedTags}
              onChange={(data) => setTags(data)}
            />
          </label>
          <br />
          <label htmlFor="rawScore" style={{ display: 'inline' }}>
            Raw Score
            <input type="number" className="form-control" value={rawScore} onChange={(event) => { setRawScore(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="rawHighest" style={{ display: 'inline' }}>
            Highest Available Score
            <input type="number" className="form-control" value={rawHighest} onChange={(event) => { setRawHighest(event.target.value); }} />
          </label>
          <br />
          <label htmlFor="percentage" style={{ display: 'inline' }}>
            Calculated Percentage
            <input type="number" className="form-control" value={percentage} />
          </label>
          <br />
          <label htmlFor="assignee" className="form-label" style={{ display: 'inline' }}>
            Assignee
            <select
              className="form-select"
              value={assignee}
              onChange={(event) => { setAssignee(event.target.value); }}
            >
              <option disabled selected value> -- Select an Assignee -- </option>
              {member.map((item) => (
                <option value={item._id} key={item._id}>{item.name}</option>
              ))}
            </select>
          </label>
          <br />
          <label htmlFor="feedback" className="form-label">
            Client Feedback
            <textarea name="feedback" id="feedback" rows="5" cols="50" className="form-control" value={feedback} onChange={(event) => { setFeedback(event.target.value); }} />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => { closeAddQuestion.current(); clearAllfield(); }}>Close</Button>
          <Button type="button" color="primary" onClick={async () => { await createQuestion(reference, theme, selectedTags, rawScore, rawHighest, percentage, assignee, feedback); clearAllfield(); closeAddQuestion.current(); }}>Add Question</Button>
        </Modal.Footer>
      </form>
    </Modal>

  );
}

AddQuestionModal.defaultProps = {
};

AddQuestionModal.propTypes = {
  member: PropTypes.instanceOf(Object).isRequired,
  openAddQuestion: PropTypes.func.isRequired,
  closeAddQuestion: PropTypes.func.isRequired,
  enumResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagResponse: PropTypes.arrayOf(PropTypes.string).isRequired,

};

export default AddQuestionModal;

import http from './http';

async function getBid({
  like, name, limit, offset, sortby, reference,
}) {
  const result = await http({
    method: 'GET',
    url: '/api/bid',
    params: {
      like,
      name,
      limit,
      offset,
      sortby,
      reference,
    },
  });

  return result;
}

export default getBid;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

export default function Date({
  className, id, name, children, style, setField,
}) {
  const [date, setDate] = useState('');
  const [dateError, setDateError] = useState(false);
  const [dateStart, setDateStart] = useState(true);
  useEffect(() => {
    setField(date);
  }, [date]);
  useEffect(() => {
    if (typeof date === 'string' && date.trim().length === 0 && dateStart === false) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    setDateStart(false);
  }, [date]);

  function handleChange(event) {
    const updatedDate = event.target.value;
    setDate(updatedDate);
  }

  return (
    <label htmlFor="date">
      {children}
      <input type="date" value={date} onChange={handleChange} onBlur={handleChange} name={name} id={id} className={className} style={style} />
      <Alert variant="danger" style={{ display: dateError ? 'block' : 'none' }}>
        <p>Please enter the date in the correct DD/MM/YY format</p>
      </Alert>
    </label>
  );
}

Date.defaultProps = {
  className: '',
  id: '',
  name: '',
  style: {},
  setField: () => true,
};

Date.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf,
  setField: PropTypes.func,
};

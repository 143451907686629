import React, { useEffect } from 'react';
import { useGetBid } from '../hooks/useGetBid';
import Header from '../Components/Header';
import DashTitle from '../Components/DashTitle';
import BidTable from '../Components/BidTable';
import { useDashboardContext } from './DashboardContext';
import { useGetTotalBids } from '../hooks/useGetTotalBids';
import LoadSpinner from '../Components/presentation/LoadSpinner';
import Pagination from '../Components/Pagination';

function Dashboard() {
  const {
    limit, searchName, sortBy, offset, setBids, isLoading, setLoading,
  } = useDashboardContext();

  const { totalBidsResponse, totalBidsError } = useGetTotalBids();

  const { response, error } = useGetBid({
    like: 'true',
    name: searchName,
    limit,
    offset,
    sortby: sortBy,
  }, [limit, offset, sortBy, searchName]);
  useEffect(() => {
    if (error != null || totalBidsError != null) {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }

    if (response != null && totalBidsResponse != null) {
      setBids(response);
      setLoading(false);
    }
  }, [response, isLoading, totalBidsResponse, totalBidsError]);
  // this makes sure that the database has responded before the page is loaded.

  if (isLoading) {
    return <div><LoadSpinner /></div>;
  }

  return (
    <div>
      <Header />
      <div className="container">
        <DashTitle />
        <BidTable totalBidsResponse={totalBidsResponse} />
        <Pagination totalBidsResponse={totalBidsResponse} />
      </div>
    </div>
  );
}

export default Dashboard;

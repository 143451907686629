import http from './http';
import toastItem from '../Components/presentation/toastItem';

const createBid = async ({
  closeFunc,
  salesCloudRefInput,
  trelloIdInput,
  nameInput,
  clientInput,
  yearInput,
  statusInput,
  typeInput,
  bidShowHandler,

}) => {
  const types = typeInput.map((type) => type.label);
  try {
    await http({
      method: 'POST',
      url: '/api/bid',
      data: {
        salesCloudReference: salesCloudRefInput,
        trelloId: trelloIdInput,
        name: nameInput,
        client: clientInput,
        year:
                    `${new Date(yearInput).toLocaleDateString('en-US')
                    } 00:00:00:000Z`,
        status: statusInput,
        type: types,
      },
    });
    closeFunc.current();
    bidShowHandler();
    toastItem(true, 'SUCCESS: bid created');
  } catch (err) {
    if (err.message === 'Request failed with status code 400') {
      toastItem(false, 'ERROR: A bid with that Sales Cloud Reference already exists');
    } else {
      toastItem(false, 'Error: bad request');
    }
  }
};

export default createBid;

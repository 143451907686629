import axios from 'axios';

// axios.defaults.baseURL = 'https://localhost:8080';

async function http(axiosParams) {
  const result = await axios.request(axiosParams);

  return result;
}

export default http;

import { useEffect, useState } from 'react';
import getMembers from '../services/getMembers';

export function useGetMembers() {
  const [persons, setPerson] = useState();
  const [error, setError] = useState();

  const fetchPersons = async () => {
    try {
      const result = await getMembers();
      setPerson(result);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchPersons();
  }, []);
  return { persons, error };
}

export default useGetMembers;

import http from './http';

async function updateBidMember(person) {
  let result;
  let error;
  try {
    result = await http({
      method: 'PUT',
      url: '/api/person',
      data: {
        _id: person._id,
        ibmId: person.ibmId,
        name: person.name,
        band: person.band,
        department: person.department,
        bidsWorked: person.bidsWorked,
      },
    });
  } catch (err) {
    error = err;
  }
  return { result, error };
}

export default updateBidMember;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import MultiSelect from 'react-select';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Modal from '../../Components/presentation/Modal';
import Text from '../../Components/presentation/Text';
import Date from '../../Components/presentation/Date';
import createBid from '../../services/createBid';
import SelectForm from '../../Components/presentation/SelectForm';

function AddNewBid({

  enumResponse,
}) {
  const openFunc = React.useRef(null);
  const closeFunc = React.useRef(null);

  const [disable, setDisable] = useState(true);
  const [nameInput, setNameInput] = useState();
  const [clientInput, setClientInput] = useState();
  const [yearInput, setYearInput] = useState();
  const [statusInput, setStatusInput] = useState();
  const [typeInput, setTypeInput] = useState();
  const [salesCloudRefInput, setSalesCloudRefInput] = useState();
  const [trelloIdInput, setTrelloIdInput] = useState();

  const types = [
    'AMS', 'DATA', 'DIGITAL', 'PLATFORM', 'STAFF AUGMENTATION',
  ];

  const navigate = useNavigate();
  const bidShowHandler = () => {
    const ISC_REF = salesCloudRefInput;
    navigate('../bidview', { replace: true, state: { ISC_REF } });
  };

  useEffect(() => {
    if (salesCloudRefInput === undefined
          || nameInput === undefined
          || clientInput === undefined
          || yearInput === undefined
          || statusInput === undefined
          || typeInput === undefined
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [salesCloudRefInput, nameInput, clientInput, yearInput, statusInput, typeInput]);

  return (
    <div className="d-inline">
      <button
        className="btn btn-primary ms-1"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#newBidModal"
        onClick={() => openFunc.current()}
      >
        Add New Bid
        <i className="bi-plus" />
      </button>
      <Modal title="New Bid" openFunc={openFunc} closeFunc={closeFunc}>
        <Modal.Body>
          <form>
            <Text
              type="Sales Cloud Reference"
              className="form-control"
              required
              validationFn={(inputString) => {
                if (inputString.trim().length) { return true; } return false;
              }}
              validationErrorMessage="Sales Cloud Reference is required."
              placeholder="Sales Cloud Reference is a required field"
              value={salesCloudRefInput}
              setField={(data) => {
                setSalesCloudRefInput(data);
              }}
            >
              Sales Cloud Reference *
            </Text>
            <Text
              type="Trello Id"
              className="form-control"
              required
              validationFn={(inputString) => {
                if (inputString.trim().length) { return true; } return false;
              }}
              validationErrorMessage="Trello ID is required."
              placeholder="Trello ID is a required field"
              setField={(data) => setTrelloIdInput(data)}
              value={trelloIdInput}
            >
              Trello ID *
            </Text>
            <Text
              type="Name"
              className="form-control"
              required
              validationFn={(inputString) => {
                if (inputString.trim().length) { return true; } return false;
              }}
              validationErrorMessage="Name is required."
              placeholder="Name is a required field"
              value={nameInput}
              setField={(data) => { setNameInput(data); }}
            >
              Name *
            </Text>
            <Text
              Type="Client"
              className="form-control"
              required
              validationFn={(inputString) => {
                if (inputString.trim().length) { return true; } return false;
              }}
              validationErrorMessage="Client is required."
              placeholder="Client is a required field"
              value={clientInput}
              setField={(data) => { setClientInput(data); }}
            >
              Client *
            </Text>
            <Date
              type="Year"
              className="form-control"
              value={yearInput}
              setField={(data) => { setYearInput(data); }}
              validationErrorMessage="Year is required."
              style={{ width: '466px' }}
            >
              Year *
            </Date>
            <br />
            <SelectForm
              type="Status"
              setField={(data) => { setStatusInput(data); }}
              validationErrorMessage="Status is required."
              items={enumResponse}
            >
              Status *
            </SelectForm>

            <label htmlFor="typeLabel" className="form-label" style={{ display: 'inline' }}>
              Tags *
              <MultiSelect
                isMulti
                options={types.map((type, index) => ({
                  label: type,
                  value: index,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                value={typeInput}
                onChange={(data) => { setTypeInput(data); }}
              />
            </label>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={async () => { await closeFunc.current(); setTypeInput(''); }}
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={disable}
            onClick={async () => {
              await createBid({
                closeFunc,
                salesCloudRefInput,
                trelloIdInput,
                nameInput,
                clientInput,
                yearInput,
                statusInput,
                typeInput,
                bidShowHandler,
              });
            }}
          >
            Add Bid
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

AddNewBid.propTypes = {
  enumResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default AddNewBid;

import http from './http';
import toastItem from '../Components/presentation/toastItem';

// eslint-disable-next-line max-len
const EditBid = async (
  bid,
  nameInput,
  clientInput,
  yearInput,
  statusInput,
  typeInput,
  closeFunc,
  setBid
) => {
  const { _id } = bid;
  const newBid = { ...bid };
  if (nameInput !== '') {
    newBid.name = nameInput;
  }
  if (yearInput !== '') {
    newBid.year = yearInput;
  }
  if (clientInput !== '') {
    newBid.client = clientInput;
  }
  newBid.status = statusInput;
  newBid.type = typeInput;
  let updatedBid;
  try {
    updatedBid = await http({
      method: 'PUT',
      url: '/api/bid',
      data: {
        _id,
        bid: newBid,
      },
    });
    toastItem(true, 'Bid successfully updated!');
    //closeFunc.current();
  } catch (err) {
    toastItem(false, 'Bid failed to update!');
  }

  setBid(updatedBid.data);
};

export default EditBid;

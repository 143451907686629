import http from './http';
import toastItem from '../Components/presentation/toastItem';
import getRates from './getRates';

const EditRate = async ({
  _id, band, cost, startDate, endDate, setRates, closeFunc,
}) => {
  try {
    await http({
      method: 'PUT',
      url: '/api/rates',
      data: {
        _id,
        band,
        cost,
        startDate: `${new Date(startDate).toLocaleDateString('en-US')} `,
        endDate: `${new Date(endDate).toLocaleDateString('en-US')}`,
      },
    });
    toastItem(true, 'Rate successfully edited!');
    closeFunc.current();
    const rateResponse = await getRates({
      like: 'true',
    });
    setRates(rateResponse.data);
  } catch (err) {
    toastItem(false, 'Rate failed to edit!');
  }
};

export default EditRate;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import removeQuestion from '../../services/removeQuestion';
import EditQuestionModal from './EditQuestionModal';
import exportQuestion from '../../services/exportQuestion';
import uploadImportQuestions from '../../services/uploadImportQuestion';
import Button from '../presentation/Button';
import Modal from '../presentation/Modal';

function QuestionView({
  bid, setBid, enumResponse, tagResponse,
}) {
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [fileObj, setFileObj] = useState();
  const [sortByTheme, setSortByTheme] = useState('All');
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const openEditQuestion = React.useRef(null);
  const closeEditQuestion = React.useRef(null);
  const openFuncDeleteQuestion = React.useRef(null);
  const closeFuncDeleteQuestion = React.useRef(null);
  const openCSVImport = React.useRef(null);
  const closeCSVImport = React.useRef(null);

  const deleteModalOpen = (question) => {
    setCurrentQuestion(question);
    openFuncDeleteQuestion.current();
  };

  const getAssigneeName = (assignee) => {
    const result = bid.team.filter((obj) => obj._id === assignee);
    if (result.length === 0) {
      return (
        <div> no member asigned </div>
      );
    }
    const asignedName = result[0].name;
    return asignedName;
  };

  useEffect(() => {
    if (
      sortByTheme === 'All'
    ) {
      setFilteredQuestions(bid.questions);
    } else {
      const updatedQuestionsList = bid.questions.filter((obj) => obj.theme === sortByTheme);

      setFilteredQuestions(updatedQuestionsList);
    }
  }, [sortByTheme, bid]);

  return (
    <div className="tab-content" id="questionsTab">
      <div className="tab-content" id="questions" role="tabpanel" aria-labelledby="questions-tab">
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="mt-2">Questions</h4>
          </div>
          <div className="col-12 col-md-6 text-end">
            <Button className="btn btn-outline-primary" type="button" onClick={() => openCSVImport.current()}>
              Import Questions
              {' '}
              <i className="bi-plus" />
            </Button>
            <Button
              type="button"
              className="btn btn-outline-primary"
              onClick={async () => { await exportQuestion({ bid, filteredQuestions }); }}
            >
              Export
              Questions
              {' '}
              <i className="bi bi-download" />
            </Button>
          </div>
        </div>
        <h6>Theme Filter:</h6>
        <select
          value={sortByTheme}
          onChange={(event) => { setSortByTheme(event.target.value); }}
        >
          <option value="" disabled selected>Select Theme</option>
          <option value="All">All</option>
          {enumResponse[1].map((item) => (
            <option value={item} key={item}>{item}</option>
          ))}
          {' '}
        </select>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Question Reference</th>
              <th scope="col">Theme</th>
              <th scope="col">Tags</th>
              <th scope="col">Raw Score</th>
              <th scope="col">Highest Available Score</th>
              <th scope="col">Percentage (%)</th>
              <th scope="col">Assignee</th>
              <th scope="col">Client Feedback</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {filteredQuestions.map((question) => (
              <tr>
                <td key={question._id}>{question.reference}</td>
                <td>
                  <span className="badge bg-secondary" />
                  {question.theme}
                </td>
                <td>
                  {question.tags.map((tags) => (
                    <span className="badge bg-secondary">{tags}</span>
                  ))}
                </td>
                <td>{question.rawScore}</td>
                <td>{question.rawHighest}</td>
                <td>{question.percentage}</td>
                <td>
                  {/* {question.assignee} */}
                  {getAssigneeName(question.assignee)}
                </td>
                <td>{question.feedback}</td>
                <td className="text-end">
                  <Button
                    className="btn btn-link link-primary p-0"
                    title="Edit question"
                    onClick={() => { setCurrentQuestion(question); openEditQuestion.current(); }}
                    type="submit"
                  >
                    Edit Question
                    <i className="bi-pencil" />
                  </Button>
                  <Button
                    className="btn btn-link link-danger p-0"
                    title="Remove Question"
                    type="button"
                    onClick={() => deleteModalOpen(question)}
                  >
                    Remove Question
                    <i className="bi-x-square" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="questionModalContainer">
          <EditQuestionModal
            openEditQuestion={openEditQuestion}
            closeEditQuestion={closeEditQuestion}
            question={currentQuestion}
            bid={bid}
            setBid={setBid}
            enumResponse={enumResponse}
            tagResponse={tagResponse}
          />
          <Modal id="deleteQuestion" title="Delete Question" openFunc={openFuncDeleteQuestion} closeFunc={closeFuncDeleteQuestion}>
            <Modal.Body>
              <form id="deleteText">
                <label htmlFor="deleteLabel" style={{ display: 'inline', color: 'red' }}>
                  <strong>Warning:</strong>
                  {' '}
                  This cannot be undone. All question details will be permanently deleted
                </label>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" color="secondary" onClick={() => closeFuncDeleteQuestion.current()}>Cancel</Button>
              <Button
                type="button"
                color="danger"
                onClick={async () => {
                  await removeQuestion({
                    bid,
                    question: currentQuestion,
                    setBid,
                    closeFuncDeleteQuestion,
                  });
                }}
              >
                Delete Question
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            id="importCSVQuestion"
            title="Import csv question"
            openFunc={openCSVImport}
            closeFunc={closeCSVImport}
          >
            <Modal.Body>
              <p>
                Questions must be imported using a
                {' '}
                <strong>.csv</strong>
                {' '}
                file format for it to work correctly. An example of the format
                can be found
                {' '}
                <a href="../../../public/assets/importCSVQuestion.csv" target="_blank" download>here</a>
                .
              </p>
              <form>
                <input
                  type="file"
                  name="csvFile"
                  className="form-control"
                  accept=".csv"
                  onChange={(e) => {
                    setFileObj(e.target);
                  }}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => closeCSVImport.current()}
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-1"
                  onClick={async () => {
                    await uploadImportQuestions({
                      closeCSVImport, fileObj, bid, setBid, setFilteredQuestions,
                    });
                  }}
                >
                  Import csv File
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
        {bid.questions.length <= 0 && (
        <div className="alert alert-info">
          <i className="bi-info-circle" />
          {' '}
          <strong>NOTICE:</strong>
          {' '}
          There are no questions linked to this bid.
        </div>
        ) }

      </div>
    </div>
  );
}
QuestionView.defaultProps = {
};

QuestionView.propTypes = {
  bid: PropTypes.instanceOf(Object).isRequired,
  setBid: PropTypes.func.isRequired,
  enumResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default QuestionView;

import http from './http';
import toastItem from '../Components/presentation/toastItem';

const exportBids = async () => {
  try {
    const response = await http({
      method: 'GET',
      url: '/api/bid/count',
    });

    const totalBids = response.data.bidCount; // Gives the total number of bids in the database.

    const bids = await http({
      method: 'GET',
      url: '/api/bid',
      params: {
        like: 'true',
        limit: totalBids,
      },
    });

    const headers = ['salesCloudReference',
      'trelloId',
      'name',
      'client',
      'year',
      'status',
      'type'];

    let csvRows = [];

    const headerValues = headers.map((header) => (header));
    csvRows.push(headerValues);
    const bidsArray = bids.data.map((bid) => (
      [`"${bid.salesCloudReference}"`, `"${bid.trelloId}"`, `"${bid.name}"`, `"${bid.client}"`,
        `"${(new Date(bid.year).toLocaleDateString())}"`, `"${bid.status}"`, `"${bid.type}"`]));

    csvRows = csvRows.concat(bidsArray);

    const csvFile = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    const date = new Date();
    const dateString = `${date.getDate()
    }-${
      date.getMonth() + 1
    }-${
      date.getFullYear()}`;
    const dateStringUrl = dateString.toString();

    downloadLink.download = `bid-profile-${dateStringUrl}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    toastItem(true, `SUCCESS: Bids exported to file ${downloadLink.download}`);
  } catch (err) {
    toastItem(false, 'ERROR: fail to export bids');
  }
};

export default exportBids;

import React, { useEffect } from 'react';
import exportBids from '../services/bidExport';
import Toast from './presentation/Toast';
import ImportBids from '../Pages/bidsButton/ImportBids';
import AddNewBid from '../Pages/bidsButton/AddNewBid';
import { useGetEnums } from '../hooks/useGetEnums';
import CreateBox from '../Pages/bidsButton/CreateBox';
import SyncTrello from '../services/SyncTrello';

function DashTitle() {
  const { enumResponse, enumError } = useGetEnums();
  useEffect(() => {
    if (enumError != null) {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  }, [enumError]);

  return (
    <div className="row">
      <div className="col-sm-2">
        <h2>Dashboard</h2>
      </div>
      <div className="col-xl-10 text-end">
        <CreateBox />
        <button onClick={SyncTrello} className="btn btn-primary ms-1" type="button">
          Sync Trello
          <i className="bi bi-arrow-repeat" />
        </button>
        {enumResponse
        && <AddNewBid enumResponse={enumResponse[0]} /> }
        <ImportBids />
        <button
          type="button"
          className="btn btn-primary ms-1"
          onClick={() => exportBids()}
        >
          Export Bids
          <i className="bi bi-download" />
        </button>
      </div>
      <Toast />
    </div>
  );
}

export default DashTitle;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Modal from '../../presentation/Modal';
import Button from '../../presentation/Button';
import removeURL from '../../../services/removeURL';
import addURL from '../../../services/addURL';
import Loading from '../../presentation/Loading';

import createBoxFolder from '../../../services/createBoxFolder';
import SpawnButtons from './SpawnButtons';

function SpawnModals({
  type, bid, setBid,
}) {
  const [input, setInput] = useState();

  const openFuncImport = React.useRef(null);
  const closeFuncImport = React.useRef(null);

  const openFuncDelete = React.useRef(null);
  const closeFuncDelete = React.useRef(null);

  const openFuncCreate = React.useRef(null);
  const closeFuncCreate = React.useRef(null);

  const show = React.useRef(null);
  const hide = React.useRef(null);

  let buttonText;
  if (type === 'trello') {
    buttonText = ' Trello ';
  } else if (type === 'slack') {
    buttonText = ' Slack ';
  } else if (type === 'box') {
    buttonText = ' Box ';
  }

  const deleteModalTitle = `Delete ${buttonText} Link`;
  const importModalTitle = `Import ${buttonText} Link`;
  const createModalTitle = `Create ${buttonText} Folder`;
  const placeHolder = `${type.toUpperCase()}://URL`;

  return (
    <div>
      <Modal id="deleteLink" title={deleteModalTitle} openFunc={openFuncDelete} closeFunc={closeFuncDelete}>
        <Modal.Body>
          <form id="text1">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="text1" style={{ display: 'inline' }}>
              Are you sure?
            </label>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => closeFuncDelete.current()}>Close</Button>
          <Button type="button" color="danger" onClick={() => removeURL(type, setBid, bid, closeFuncDelete)}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal id="importTrelloLink" title={importModalTitle} openFunc={openFuncImport} closeFunc={closeFuncImport}>
        <Modal.Body>
          <form>
            <label htmlFor="text2" style={{ display: 'inline' }}>
              Import your
              {' '}
              {buttonText}
              {' '}
              link using the text-box below.
              <br />
              <input value={input} onInput={(e) => setInput(e.target.value)} className="form-control" id="text2" placeholder={placeHolder} />
            </label>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => closeFuncImport.current()}>Close</Button>
          <Button type="button" color="primary" onClick={() => addURL(type, setBid, input, bid, closeFuncImport)}>
            Submit
            {' '}
            {buttonText}
            {' '}
            Link
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal id="createBox" title={createModalTitle} openFunc={openFuncCreate} closeFunc={closeFuncCreate}>
        <Modal.Body>
          <form>
            <label htmlFor="text3" style={{ display: 'inline' }}>
              <p>Please select in which account are you planning to bid:</p>
              <input id="text3" type="radio" value="home_office" name="active" required />
              Home Office and Police 🚔
              <br />
              <input id="text4" type="radio" value="ministry_of_defence" name="active" required />
              Ministry of Defence 🛡️
              <br />
              <input id="text5" type="radio" value="work_and_pensions" name="active" required />
              Department for Work and Pensions 🔨
            </label>
            <Alert variant="danger" id="boxError" style={{ visibility: 'hidden' }}>
              <p>Please select a department where you want to create the box folder </p>
            </Alert>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => closeFuncCreate.current()}>Close</Button>
          <Button id="submitBtn" type="button" color="primary" onClick={() => { (createBoxFolder(document.querySelector('input[name="active"]:checked') === null ? undefined : document.querySelector('input[name="active"]:checked').value, setBid, bid, closeFuncCreate, show)); }}>
            Submit
            {' '}
            {buttonText}
          </Button>
        </Modal.Footer>
        <Loading title="Creating Box Folder" show={show} hide={hide} animation="border" style={{ width: '3rem', height: '3rem', margin: '5px' }} />
      </Modal>

      <SpawnButtons
        type={type}
        buttonText={buttonText}
        bid={bid}
        openFuncImport={openFuncImport}
        openFuncDelete={openFuncDelete}
        openFuncCreate={openFuncCreate}
      />
    </div>
  );
}

SpawnModals.propTypes = {
  type: PropTypes.string.isRequired,
  bid: PropTypes.instanceOf(Object).isRequired,
  setBid: PropTypes.func.isRequired,
};

export default SpawnModals;

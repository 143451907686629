import http from './http';

async function getRates({
  like,
}) {
  const result = await http({
    method: 'GET',
    url: '/api/rates',
    params: {
      like,
    },
  });

  return result;
}

export default getRates;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import {
  Chart as ChartJS, registerables,
} from 'chart.js';

import BarChartModal from './BarChartModal';
import { useGetBid } from '../../../hooks/useGetBid';
import createStatusData from './createStatusData';

ChartJS.register(...registerables);

function BarChart({ chartData, options }) {
  const [identifiedPoints, setIdentifiedPoints] = useState([]);
  const [date, setDate] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [modalData, setModalData] = useState([]);

  // chart
  const chartRef = useRef(null);

  // useRef functions used to open and close modal
  const openModelFunc = useRef(null);
  const closeModelref = useRef(null);

  // retrieve bids so that the bids in the selected status can be displayed
  const { response: bids, error } = useGetBid({
    like: 'true',
  });

  // this runs whenever a user clicks a bar
  useEffect(() => {
    // if a bar was selected, this to avoid running the effect the first time component renders
    if (identifiedPoints.length !== 0) {
      const clickedDatasetIndex = identifiedPoints[0].datasetIndex;
      const clickedStatus = chartData.datasets[clickedDatasetIndex].label;
      const clickedElementIndex = identifiedPoints[0].index;
      const clickDate = chartData.labels[clickedElementIndex];

      setDate(clickDate);
      setStatus(clickedStatus);

      // prepare all the data that will be displayed in the modal
      const preparedData = createStatusData({ clickDate, bids, clickedStatus });
      setModalData(preparedData);
      // open modal
      openModelFunc.current();
    }
  }, [identifiedPoints]);

  const handleClick = (event) => {
    const { current: chart } = chartRef;

    // if the event triggered is not about a chart, then do nothing.
    if (!chart) {
      return;
    }

    // clickedPoint will have length of more than zero only
    // if clicked on a valid datapoint on the chart
    const clickedPoints = getElementAtEvent(chart, event);

    // onClick triggers an event whenever anywhere in the chart is clicked
    // only bar(s) have clicked point(s) and if its not a bar, we dont do a thing
    // visit the react chart library and read about more about events
    if (clickedPoints.length === 0) {
      return;
    }

    // then the event was truggered by an intersection and preserve the
    // points in a state to be accessed elsewhere
    setIdentifiedPoints(clickedPoints);
  };

  return (
    <>
      <Bar
        data={chartData}
        options={options}
        ref={chartRef}
        onClick={handleClick}
      />
      <BarChartModal
        open={openModelFunc}
        close={closeModelref}
        status={status}
        date={date}
        error={error}
        modalData={modalData}
      />
    </>
  );
}

BarChart.defaultProps = {
  chartData: {},
  options: {},
};

BarChart.propTypes = {
  chartData: PropTypes.shape,
  options: PropTypes.shape,
};

export default BarChart;

import { useState, useEffect } from 'react';
import http from '../services/http';

export const useGetChart = ({
  limit, offset, sortby, name, like, reference,
}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const result = await http({
        method: 'GET',
        url: '/api/insights',
        params: {
          like,
          name,
          limit,
          offset,
          sortby,
          reference,
        },
      });
      setResponse(result.data);
    } catch (oops) {
      setError(oops);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // execute once only

  return { useGetChartsResponse: response, useGetChartsError: error };
};

export default useGetChart;

import http from './http';

async function getEnumValues() {
  const result = await http({
    method: 'GET',
    url: '/enumValues',
  });
  return result;
}
export default getEnumValues;

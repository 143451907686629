import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './presentation/Modal';
import Text from './presentation/Text';
import Date from './presentation/Date';
import Button from './presentation/Button';
import AddRate from '../services/addRate';

function AddRateModal({
  openAddRate, closeAddRate, setRates,
}) {
  const [band, setBand] = useState('');
  const [cost, setCost] = useState('0');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Modal id="addRate" title="Add Rate" openFunc={openAddRate} closeFunc={closeAddRate}>
      <Modal.Body>
        <form>
          <Text
            Type="Band"
            className="form-control"
            required
            validationFn={(inputString) => {
              if (inputString.trim().length) { return true; } return false;
            }}
            validationErrorMessage="Band is required."
            placeholder="Band      is a required field"
            value={cost}
            setField={(data) => { setBand(data); }}
          >
            Band
          </Text>
          <br />
          <Text
            Type="Cost"
            className="form-control"
            required
            validationFn={(inputString) => {
              if (inputString.trim().length) { return true; } return false;
            }}
            validationErrorMessage="Cost is required."
            placeholder="Cost      is a required field"
            value={cost}
            setField={(data) => { setCost(data); }}
          >
            Cost
          </Text>
          <br />
          <Date
            type="startDate"
            className="form-control"
            value={startDate}
            setField={(data) => { setStartDate(data); }}
            validationErrorMessage="Start Date is required."
            style={{ width: '466px' }}
          >
            Start Date
          </Date>
          <br />
          <Date
            type="endDate"
            className="form-control"
            value={endDate}
            setField={(data) => { setEndDate(data); }}
            validationErrorMessage="End Date is required."
            style={{ width: '466px' }}
          >
            End Date
          </Date>

        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" color="secondary" onClick={() => closeAddRate.current()}>Close</Button>
        <Button
          type="button"
          color="primary"
          onClick={async () => {
            await AddRate({
              band,
              cost,
              startDate,
              endDate,
              setRates,
              closeAddRate,
            });
          }}
        >
          Add Rate

        </Button>
      </Modal.Footer>
    </Modal>

  );
}

AddRateModal.defaultProps = {
};

AddRateModal.propTypes = {
  setRates: PropTypes.func.isRequired,
  openAddRate: PropTypes.func.isRequired,
  closeAddRate: PropTypes.func.isRequired,
};

export default AddRateModal;

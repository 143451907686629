import http from './http';

async function getTotalBids() {
  const result = await http({
    method: 'GET',
    url: '/api/bid/count',
  });
  return result;
}
export default getTotalBids;

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import Modal from '../../presentation/Modal';
import Button from '../../presentation/Button';
import toastItem from '../../presentation/toastItem';

function BarChartModal({
  open, close, date, status, error, modalData,
}) {
  const navigate = useNavigate();

  const openBid = ({ ISC_REF, bidName }) => {
    // close modal
    close.current();
    // navigate to the bid view by changing the fragment identifier in the url.
    navigate('../bidview', { replace: true, state: { ISC_REF } });
    // toast
    toastItem(true, `Opened Bid: ${bidName}`);
  };
  return (
    <Modal id="barChartModal" title={`${date} ${status} Bid(s)`} openFunc={open} closeFunc={close}>
      <Modal.Body>
        {typeof error !== 'undefined' && <p>Error in retrieving bid(s) information:(</p>}
        {modalData?.length !== 0 && (
        <Accordion allowMultipleExpanded preExpanded={[0]}>
          {modalData?.map((item, index) => (
            <AccordionItem uuid={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <strong>
                    Bid:
                    {' '}
                    {item.bidName}
                  </strong>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Client:
                  {' '}
                  {item.client}
                </p>
                <Button type="button" color="primary" onClick={() => openBid({ ISC_REF: item.salesCloudReference, bidName: item.bidName })}> Open Bid</Button>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" color="secondary" onClick={() => close.current()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

BarChartModal.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  modalData: PropTypes.shape.isRequired,
};

export default BarChartModal;

import http from './http';
import getBid from './getBid';
import toastItem from '../Components/presentation/toastItem';

const uploadImportQuestions = async ({
  closeCSVImport, fileObj, bid, setBid, setFilteredQuestions,
}) => {
  if (typeof fileObj === 'undefined') { toastItem(false, 'ERROR: no file selected'); return; }
  const file = fileObj.files[0]; // selecting only the first file
  const fd = new FormData(); // upload worked only when FormData is used
  fd.append('csvfile', file);

  if (typeof file !== 'undefined') {
    try {
      const uploadedFile = await http({
        method: 'POST',
        url: '/api/importQuestion/post',
        data: fd,
        headers: { 'Content-Type': 'Multipart/form-data' }, // must to make the upload work
      });
      const questions = uploadedFile.data[1];
      for (let i = 0; i < questions.length; i += 1) {
        bid.questions.push(questions[i]);
      }

      await http({
        method: 'PUT',
        url: '/api/bid',
        data: {
          bid,
        },
      });
      setFilteredQuestions(bid.questions);
      closeCSVImport.current();
      const bidResponse = await getBid({
        reference: bid.salesCloudReference,
      });

      setBid(bidResponse.data);
      toastItem(true, 'SUCCESS: questions imported from file!');
    } catch (err) {
      toastItem(false, 'ERROR: file was not imported');
    }
  }
};
export default uploadImportQuestions;

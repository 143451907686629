import http from './http';

async function updateBid(bid) {
  let updatedBid;
  let error;
  try {
    updatedBid = await http({
      method: 'PUT',
      url: '/api/bid',
      data: {
        bid,
      },
    });
  } catch (err) {
    error = err;
  }
  return { updatedBid, error };
}

export default updateBid;

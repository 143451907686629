import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Insights from './Pages/Insights';
import Rates from './Pages/Rates';
import BidView from './Pages/BidView';
import Demo from './Pages/Demo';
import { DashboardContextProvider } from './Pages/DashboardContext';
import PageNotFound from './Pages/NotFoundPage';

import './app.css';

// import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const routes = useRoutes([
    { path: '/', element: <Login /> },
    { path: '/dashboard', element: <DashboardContextProvider><Dashboard /></DashboardContextProvider> },
    { path: '/insights', element: <Insights /> },
    { path: '/rates', element: <Rates /> },
    { path: '/bidview', element: <BidView /> },
    { path: '/demo', element: <Demo /> },
    { path: '/*', element: <PageNotFound /> },
  ]);

  return routes;
};

function AppWrapper() {
  return (
    <div>
      <Router>
        <App />
      </Router>
    </div>
  );
}

export default AppWrapper;

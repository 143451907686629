import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Button from './Button';
import './Modal.css';

function Body({ children }) {
  return (
    <div className="modal-body">{children}</div>
  );
}

function Footer({ children }) {
  return (
    <div className="modal-footer">{children}</div>
  );
}
function Modal({
  id, title, children, openFunc, closeFunc,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    openFunc.current = openModal;
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    closeFunc.current = closeModal;
  }, []);
  return (
    <ReactModal
      id={id}
      className="Modal__Bootstrap modal-dialog modal-dialog-centered"
      closeTimeoutMS={150}
      isOpen={isOpen}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{title}</h4>
          <Button type="button" color="close" onClick={closeModal} />
        </div>
        {children}
      </div>
    </ReactModal>
  );
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  title: '',
  closeFunc: () => {},
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  openFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;

import React from 'react';
import Text from '../Components/presentation/Text';
import Button from '../Components/presentation/Button';
import SelectForm from '../Components/presentation/SelectForm';
import TabComponent from '../Components/presentation/Tabs';
import Date from '../Components/presentation/Date';
import Loading from '../Components/presentation/Loading';
import Modal from '../Components/presentation/Modal';
import TeamProfileView from '../Components/bidViews/TeamProfileView';
import QuestionView from '../Components/bidViews/QuestionsView';
import BoxFolderView from '../Components/bidViews/BoxFolderView';
import SlackView from '../Components/bidViews/SlackView';
import BarChart from '../Components/insights/BarChart/BarChart';
import LineChart from '../Components/insights/LineChart/LineChart';
import {
  lineData, lineOptions, barOptions, barData,
} from './demoData';
import Accordion from '../Components/presentation/Accordion';

function Demo() {
  const openFunc = React.useRef(null);
  const closeFunc = React.useRef(null);

  const show = React.useRef(null);
  const hide = React.useRef(null);

  return (
    <div>
      <br />
      <h3 style={{ margin: '5px' }}>Button Component</h3>
      <Button>No Params</Button>
      <Button color="primary" style={{ margin: '5px' }}>Primary</Button>
      <Button color="secondary" style={{ margin: '5px' }}>Secondary</Button>
      <Button color="success" style={{ margin: '5px' }}>Success</Button>
      <Button color="danger" style={{ margin: '5px' }}>Danger</Button>
      <Button color="warning" style={{ margin: '5px' }}>Warning</Button>
      <Button color="info" style={{ margin: '5px' }}>Info</Button>
      <Button color="light" style={{ margin: '5px' }}>Light</Button>
      <Button color="dark" style={{ margin: '5px' }}>Dark</Button>
      <Button color="link" style={{ margin: '5px' }}>Link</Button>
      <br />
      <Button outline="outline-primary" style={{ margin: '5px' }}>Primary</Button>
      <Button outline="outline-secondary" style={{ margin: '5px' }}>Secondary</Button>
      <Button outline="outline-success" style={{ margin: '5px' }}>Success</Button>
      <Button outline="outline-danger" style={{ margin: '5px' }}>Danger</Button>
      <Button outline="outline-warning" style={{ margin: '5px' }}>Warning</Button>
      <Button outline="outline-info" style={{ margin: '5px' }}>Info</Button>
      <Button outline="outline-light" style={{ margin: '5px' }}>Light</Button>
      <Button outline="outline-dark" style={{ margin: '5px' }}>Dark</Button>
      <br />
      <hr />
      <h3 style={{ margin: '5px' }}>Modal Component</h3>
      <Button color="primary" onClick={() => openFunc.current()} style={{ margin: '5px' }}>Open Modal</Button>
      <Modal id="exampleModal" title="Example Modal" openFunc={openFunc} closeFunc={closeFunc}>
        <Modal.Body>
          <form>
            <label htmlFor="text" style={{ display: 'inline' }}>
              Sample Text Input
              <br />
              <input className="form-control" id="text" />
            </label>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => closeFunc.current()}>Close</Button>
          <Button type="button" color="primary">Save changes</Button>
        </Modal.Footer>
      </Modal>
      <div>
        <h3 style={{ margin: '5px' }}>Form Selector Component</h3>
        <SelectForm items={['AMS', 'DATA']} />
      </div>
      <h3 style={{ margin: '5px' }}>Tabs Component</h3>
      <TabComponent items={['Information', 'Slack Channel', 'Team profile', 'Questions', 'Box Folder']}>
        <h4>Information</h4>
        <SlackView />
        <TeamProfileView />
        <QuestionView />
        <BoxFolderView />
      </TabComponent>
      <br />
      <hr />
      <h3 style={{ margin: '5px' }}>Date Input Component</h3>
      <Date className="form-control" style={{ margin: '5px' }} />
      <hr />
      <h3 style={{ margin: '5px' }}>Loading Component</h3>
      <Button color="primary" style={{ margin: '5px' }} onClick={() => show.current()}>Show</Button>
      <Button color="primary" style={{ margin: '5px' }} onClick={() => hide.current()}>Hide</Button>
      <Loading title="Loading..." show={show} hide={hide} animation="border" style={{ width: '3rem', height: '3rem' }} />
      <hr />
      <h3 style={{ margin: '5px' }}>Text Input Component</h3>
      <Text required validateString="slack.com" placeholder="Enter a valid Slack URL">Text input with validation</Text>
      <hr />
      <div style={{ width: 700 }}>
        <h3 style={{ margin: '5px' }}>BarChart Component</h3>
        <BarChart chartData={barData} options={barOptions} />
        <h3 style={{ margin: '5px' }}>LineChart Component</h3>
        <LineChart chartData={lineData} options={lineOptions} />
      </div>
      <div>
        <h5>This is an accordion</h5>
        <Accordion />
      </div>
    </div>
  );
}

export default Demo;

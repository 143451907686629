import { useState, useEffect } from 'react';
import getTags from '../services/getTags';

export const useGetTags = () => {
  const [tagResponse, setResponse] = useState(undefined);
  const [tagError, setError] = useState(undefined);

  const fetchData = async () => {
    try {
      const result = await getTags({
      });
      setResponse(result.data);
    } catch (oops) {
      setError(oops);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // execute once only

  return { tagResponse, tagError };
};

export default useGetTags;

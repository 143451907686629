/* eslint-disable no-param-reassign */
import putImportBid from './putImportBid';
import toastItem from '../Components/presentation/toastItem';
import getBid from './getBid';

async function addURL(type, setBid, importedURL, bid, closeFuncImport) {
  let url;
  if (importedURL === undefined) {
    url = '';
  } else {
    url = importedURL;
  }

  let urlIncludes = '';
  let urlState = '';
  if (type === 'trello') {
    urlIncludes = 'trello.com';
    urlState = 'Trello';
  } else if (type === 'slack') {
    urlIncludes = 'slack.com';
    urlState = 'Slack';
  } else {
    urlIncludes = 'box.com';
    urlState = 'Box';
  }

  if (url.includes(urlIncludes)) {
    try {
      await putImportBid(type, bid._id, url);
      toastItem(true, `SUCCESS: ${urlState} URL uploaded!`);
      closeFuncImport.current();
    } catch (err) {
      toastItem(false, 'ERROR: Call to database failed!');
    }
  } else {
    toastItem(false, `ERROR: '${urlIncludes}' is required in URL!`);
  }

  const bidResponse = await getBid({
    reference: bid.salesCloudReference,
  });

  setBid(bidResponse.data);
}

export default addURL;

import http from './http';
import toastItem from '../Components/presentation/toastItem';

const DeleteBid = async (bid) => {
  const { _id } = bid;
  try {
    await http({
      method: 'DELETE',
      url: '/api/bid',
      params: {
        _id,
      },
    });
    window.location.href = '/dashboard';
    toastItem(true, 'Bid successfully deleted!');
  } catch (err) {
    toastItem(false, 'Bid failed to delete!');
  }
};

export default DeleteBid;

const barData = {
  labels: ['Africa', 'Asia', 'Europe', 'Latin America', 'North America'],
  datasets: [
    {
      label: 'Population (millions)',
      backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'],
      data: [2478, 5267, 734, 784, 433],
    },
    {
      label: 'CIC Salary (millions)',
      backgroundColor: ['#497eb6', '#32cd5a', '#d7282d', '#9f6085', '#1ba8e4'],
      data: [2478, 5267, 734, 784, 433],
    },
  ],
};

const barOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Population',
    },
  },
  spanGaps: true,
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
      title: {
        display: true,
        text: 'World population per region (in millions)',
      },
      ticks: {
        stepSize: 1,
      },
    },
    x: {
      stacked: true,
      title: {
        display: true,
        text: 'Continents',
      },
    },
  },
};

const lineOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Population',
    },
  },
  spanGaps: true,
  scales: {
    y: {
      title: { display: true, text: 'World population per region (in millions)' },
    },
    x: {
      title: {
        display: true,
        text: 'Predicted world population (millions) in 2050',
      },
    },
  },
};

const lineData = {
  labels: [1500, 1600, 1700, 1750, 1800, 1850, 1900, 1950, 1999, 2050],
  datasets: [{
    data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
    label: 'Africa',
    borderColor: '#3e95cd',
    fill: false,
  }, {
    data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
    label: 'Asia',
    borderColor: '#8e5ea2',
    fill: false,
  }, {
    data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],
    label: 'Europe',
    borderColor: '#3cba9f',
    fill: false,
  }, {
    data: [40, 20, 10, 16, 24, 38, 74, 167, 508, 784],
    label: 'Latin America',
    borderColor: '#e8c3b9',
    fill: false,
  }, {
    data: [6, 3, 2, 2, 7, 26, 82, 172, 312, 433],
    label: 'North America',
    borderColor: '#c45850',
    fill: false,
  },
  ],
};

function getDemoData() {
  const response = {
    lineData, lineOptions, barData, barOptions,
  };

  const error = null;

  return { response, error };
}

export {
  getDemoData, lineData, lineOptions, barData, barOptions,
};

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

function Loading({
  show, hide, animation, variant, style, title,
}) {
  const [isLoading, setIsLoading] = React.useState(false);

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };
  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    show.current = showLoading;
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    hide.current = hideLoading;
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="row">
          <h4 className="mb-3">{title}</h4>
        </div>
        <div className="row">
          <Spinner animation={animation} variant={variant} style={style} />
        </div>
      </div>
    );
  }
}

Loading.defaultProps = {
  hide: () => {},
  variant: '',
  style: {},
  title: '',
};

Loading.propTypes = {
  show: PropTypes.func.isRequired,
  hide: PropTypes.func,
  animation: PropTypes.string.isRequired,
  variant: PropTypes.string,
  style: PropTypes.objectOf,
  title: PropTypes.string,
};

export default Loading;

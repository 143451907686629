import React from 'react';
import PropTypes from 'prop-types';
import SpawnModels from './boxSlackTrelloComponents/SpawnModels';

function SlackView({ bid, setBid }) {
  const type = 'slack';
  return (
    <div className="tab-content" id="bidTabContent">
      <div className="tab-pane fade show active p-2" id="info" role="tabpanel" aria-labelledby="info-tab">
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="mt-2">Slack</h4>
          </div>
        </div>
        <SpawnModels
          type={type}
          bid={bid}
          setBid={setBid}
        />
      </div>
    </div>
  );
}

SlackView.propTypes = {
  bid: PropTypes.instanceOf(Object).isRequired,
  setBid: PropTypes.func.isRequired,
};

export default SlackView;

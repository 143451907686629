/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetBid } from '../hooks/useGetBid';

import { useGetEnums } from '../hooks/useGetEnums';
import { useGetTags } from '../hooks/useGetTags';
// eslint-disable-next-line import/named
import { useGetMembers } from '../hooks/useGetMembers';
import AddQuestionModal from '../Components/bidViews/AddQuestionModal';
import AddTeamMemberModal from '../Components/bidViews/AddTeamMemberModal';
import BoxFolderView from '../Components/bidViews/BoxFolderView';
import Button from '../Components/presentation/Button';
import DeleteBid from '../services/DeleteBid';
import DropDownButton from '../Components/presentation/DropDownButton';
import Header from '../Components/Header';
import InformationView from '../Components/bidViews/InformationView';
import Modal from '../Components/presentation/Modal';
import QuestionView from '../Components/bidViews/QuestionsView';
import SlackView from '../Components/bidViews/SlackView';
import SpawnEditBidModal from '../Components/bidViews/SpawnEditBidModal';
import TabComponent from '../Components/presentation/Tabs';
import TeamProfileView from '../Components/bidViews/TeamProfileView';
import Toast from '../Components/presentation/Toast';
import TrelloView from '../Components/bidViews/TrelloView';

import 'react-toastify/dist/ReactToastify.css';

function BidView() {
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [bid, setBid] = useState();
  const [members, setMembers] = useState();

  const openFuncDeleteBid = React.useRef(null);
  const closeFuncDeleteBid = React.useRef(null);

  const openFuncEditBid = React.useRef(null);

  const openAddQuestion = React.useRef(null);
  const closeAddQuestion = React.useRef(null);

  const openAddTeamMember = React.useRef(null);
  const closeAddTeamMember = React.useRef(null);

  const { response, error } = useGetBid({
    reference: location.state.ISC_REF,
  });

  const { enumResponse, enumError } = useGetEnums();
  const { tagResponse, tagError } = useGetTags();
  const { persons } = useGetMembers();

  useEffect(() => {
    if (error != null || enumError != null || tagError != null) {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }

    if (response != null && enumResponse != null && tagResponse != null) {
      setBid(response);
      setLoading(false);
      setMembers(persons);
    }
  }, [response, enumResponse, tagResponse]);
  // this makes sure that the database has responded before the page is loaded
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <Toast />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>
              Bid:
              {bid.name}
              <small className="text-muted fs-6 d-block ml-0">
                REF:
                {bid.salesCloudReference}
              </small>
            </h2>
          </div>
          <div className="col-12 col-md-6 text-end">
            <DropDownButton
              outline="outline"
              color="primary"
              dropDownText="Actions"
              items={[
                {
                  name: 'Add a Question',
                  style: {},
                  action: () => openAddQuestion.current(),
                }, {
                  name: 'Add a Team Member',
                  style: {},
                  action: () => openAddTeamMember.current(),
                }, {
                  name: 'divider',
                  style: {},
                  action: () => {},
                },
                {
                  name: 'Edit Bid',
                  style: {},
                  action: () => openFuncEditBid.current(),
                }, {
                  name: 'Delete Bid',
                  style: { color: 'red' },
                  action: () => openFuncDeleteBid.current(),
                },
              ]}
            />
          </div>
        </div>
        <div className="bidModelContainer">
          <Modal id="deleteBid" title="Delete Bid" openFunc={openFuncDeleteBid} closeFunc={closeFuncDeleteBid}>
            <Modal.Body>
              <form id="deleteText">
                <label htmlFor="deleteLabel" style={{ display: 'inline', color: 'red' }}>
                  <strong>Warning:</strong>
                  {' '}
                  This cannot be undone. All bid details will be permanently deleted including
                  {' '}
                  questions. Team members will be removed from the bid and kept in the database.
                </label>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" color="secondary" onClick={() => closeFuncDeleteBid.current()}>Close</Button>
              <Button type="button" color="danger" onClick={() => DeleteBid(bid)}>Delete Bid</Button>
            </Modal.Footer>
          </Modal>
          <SpawnEditBidModal
            openFuncEditBid={openFuncEditBid}
            bid={bid}
            enumResponse={enumResponse[0]}
            setBid={setBid}
          />
          <AddQuestionModal
            member={members}
            openAddQuestion={openAddQuestion}
            closeAddQuestion={closeAddQuestion}
            enumResponse={enumResponse}
            tagResponse={tagResponse}
          />
          <AddTeamMemberModal
            openAddTeamMember={openAddTeamMember}
            closeAddTeamMember={closeAddTeamMember}
          />
        </div>
        <div className="bidNavBar">
          <TabComponent items={['Information', 'Team profile', 'Questions', 'Box Folder', 'Trello', 'Slack']}>
            <InformationView bid={bid} />
            <TeamProfileView bid={bid} setBid={setBid} />
            <QuestionView
              bid={bid}
              setBid={setBid}
              enumResponse={enumResponse}
              tagResponse={tagResponse}
            />
            <BoxFolderView bid={bid} setBid={setBid} />
            <TrelloView bid={bid} setBid={setBid} />
            <SlackView bid={bid} setBid={setBid} />
          </TabComponent>
          <Toast />
        </div>
      </div>
      <Toast />
    </div>
  );
}

export default BidView;

import { useState, useEffect } from 'react';
import getEnumValues from '../services/getEnumValues';

export const useGetEnums = () => {
  const [enumResponse, setResponse] = useState(undefined);
  const [enumError, setError] = useState(undefined);

  const fetchData = async () => {
    try {
      const result = await getEnumValues({
      });
      setResponse(result.data);
    } catch (oops) {
      setError(oops);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // execute once only

  return { enumResponse, enumError };
};

export default useGetEnums;

import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from '../../Components/presentation/Button';
import Modal from '../../Components/presentation/Modal';
import http from '../../services/http';

function CreateBox() {
  const [validated, setValidated] = useState(false);
  const [bidName, setBidName] = useState('');
  const [salesID, setSalesID] = useState('');
  const [department, setDepartment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [boxUrl, setBoxUrl] = useState('');
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const openFunc = useRef(null);
  const closeFunc = useRef(null);

  const validateForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  const createBox = async () => {
    setIsLoading(true);
    try {
      const response = await http({
        method: 'POST',
        url: '/api/bid/box-create-api',
        data: {
          bid_name: bidName,
          bid_department: department,
          bid_sales_id: salesID,
        },
      });
      setBoxUrl(response.data);
    } catch (oops) {
      setError(oops);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (event) => {
    validateForm(event);
    if (bidName.length !== 0 && salesID.length !== 0 && department.length !== 0) {
      // hide form
      setShowForm(false);
      // make request
      await createBox();
    }
  };

  const handleNameChange = ({ target }) => {
    setBidName(target.value);
  };

  const handleSalesIDChange = ({ target }) => {
    setSalesID(target.value);
  };

  const handleDepartmentChange = ({ target }) => {
    setDepartment(target.value);
  };

  const resetModal = () => {
    setShowForm(true);
    setValidated(false);
    setBidName('');
    setSalesID('');
    setDepartment('');
    setBoxUrl('');
    setError(null);
  };

  return (
    <div className="d-inline">
      <button
        type="button"
        className="btn btn-outline-primary ms-1"
        id="create-box-dashboard"
        onClick={() => openFunc.current()}
      >
        Create Box Folder
        {' '}
        <i className="bi bi-box" />
      </button>
      <Modal id="dashboard-box" title="Box Folder" openFunc={openFunc} closeFunc={closeFunc}>
        <Modal.Body>
          {/* When modal is first opened, the form is rendered */}
          {showForm && (
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="box-name-dashboard">
                <Form.Label>Bid name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name is a required field"
                  onChange={handleNameChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please provide a value.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="box-salesId-dashboard">
                <Form.Label>Sales ID</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Sales ID is a required field"
                  onChange={handleSalesIDChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please provide a value.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            Departments
            <Form.Group className="mb-3" controlId="box-deparment">
              <Form.Check
                onChange={handleDepartmentChange}
                type="radio"
                name="department"
                value="home_office"
                required
                label="Home Office and Police 🚔"
                feedback="You must select a department before submitting."
                feedbackType="invalid"
              />
              <Form.Check
                onChange={handleDepartmentChange}
                type="radio"
                name="department"
                value="ministry_of_defence"
                required
                label="Ministry of Defence 🛡️"
                feedback="You must select a department before submitting."
                feedbackType="invalid"
              />
              <Form.Check
                onChange={handleDepartmentChange}
                type="radio"
                name="department"
                value="work_and_pensions"
                required
                label="Department for Work and Pensions 🔨"
                feedback="You must select a department before submitting."
                feedbackType="invalid"
              />
            </Form.Group>
          </Form>
          )}
          {/* When the form is submitted, loading is displayed */}
          {isLoading && (
          <div className="col-xs-1 text-center">
            <Spinner
              animation="border"
              role="status"
              style={{ width: '6rem', height: '6rem' }}
            >
              <span className="visually-hidden mb3">box folder creation in progress</span>
            </Spinner>
            <p>Creating box folder...</p>
          </div>
          )}
          {/* successful request */}
          {boxUrl && (
          <Alert variant="success">
            <Alert.Heading className="text-center">Box folder created!</Alert.Heading>
            <ul style={{ listStyle: 'none' }}>
              <li>
                Bid Name:
                {' '}
                <strong>{bidName}</strong>
              </li>
              <li>
                ISC Ref:
                {' '}
                <strong>{salesID}</strong>
              </li>
              <li>
                Department:
                {' '}
                <strong>{department}</strong>
              </li>
            </ul>
            <div className="mb-0 text-center">
              <a href={boxUrl} target="_blank" rel="noreferrer" className="ms-auto">Open Box Folder</a>
            </div>
            <hr />
            <p className="text-center" style={{ color: 'red' }}>
              <strong>
                Warning: This link is
                {' '}
                <u>NOT SAVED</u>
                {' '}
                in the database!
              </strong>
            </p>
          </Alert>
          )}
          {/* rejected request */}
          {(error !== null && !showForm) && (
            <div className="col-xs-1 text-center">
              <Alert variant="danger">
                <p>Error in creating box folder:(</p>
              </Alert>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="secondary" onClick={() => closeFunc.current()}>Close</Button>
          {(boxUrl || error !== null) && <Button type="button" color="primary" onClick={resetModal}>Create another box folder</Button>}
          {showForm && <Button type="submit" color="primary" id="submit-box-dashboard" onClick={handleSubmit}>Create Box Folder</Button>}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateBox;

/* eslint-disable no-param-reassign */
import putImportBid from './putImportBid';
import toastItem from '../Components/presentation/toastItem';
import getBid from './getBid';

async function removeURL(type, setBid, bid, closeFuncDelete) {
  let urlState = '';
  if (type === 'trello') {
    urlState = 'Trello';
  } else if (type === 'slack') {
    urlState = 'Slack';
  } else {
    urlState = 'Box';
  }

  try {
    await putImportBid(type, bid._id, '');
    toastItem(true, `SUCCESS: ${urlState} URL deleted!`);
    closeFuncDelete.current();
  } catch (err) {
    toastItem(false, 'ERROR: Call to database failed!');
  }

  const bidResponse = await getBid({
    reference: bid.salesCloudReference,
  });

  setBid(bidResponse.data);
}

export default removeURL;
